#divProductCatalogue {
    &[data-catalogue-type="winecard"] {

        .product {
            flex: 0 0 100%; // display flex children as rows
            grid-template-columns: 10% 40% auto 20%;
            grid-template-areas:
                "image title title sell-area"
                "image origin origin sell-area"
                "image description description ."
                "image product-settings . .";

            @include phone {
                grid-template-columns: 20% auto 20%;
                grid-template-areas:
                    "image title title"
                    "image origin origin"
                    "image description description"
                    "image sell-area sell-area"
                    "product-settings product-settings product-settings";
            }

            .brand {
                display: none;
            }

            .origin {
                @include phone {
                    text-align: left;
                }
            }

            // .image {}

            // .description {}

            .extras {
                display: none;
            }

            .sell-area {
                display: flex;

                .prices {
                    display: flex;
                    justify-content: flex-end;

                    text-align: right;

                    // .channel-price {}

                    .channel-promo-price {
                        display: none;
                    }

                    .group-promo-price {
                        display: none;
                    }

                    .group-label {
                        display: none;
                    }

                    .winecard-price {
                        &.per {

                            &-bottle,
                            &-glass {
                                flex-grow: 1;
                                text-align: right;
                            }

                            &-bottle {
                                order: 1;
                            }

                            &-glass {
                                order: 0;
                            }
                        }
                    }
                }

                .actions {
                    grid-area: actions;

                    display: none;

                    // .product-do {}
                }
            }

            &[data-winecard-status="active"],
            &[data-winecard-status="inactive"] {

                .winecard-price {
                    display: initial;
                }
            }

            &[data-winecard-status="active"] {
                .prices {

                    .channel-price,
                    .channel-promo-price,
                    .group-promo-price,
                    .group-label {
                        display: none;
                    }
                }
            }

            &[data-winecard-status="inactive"] {
                .winecard-price {
                    display: none;
                }
            }

            // .product-settings {}
        }
    }
}