// Check if lazyload is present (JS):
// typeof($("img").lazyload) === "function"
// returns boolean

#popupBasketModal {

    .modal {

        &-dialog {
            @include phone-sm-vertical {
                margin: 0;
            }
        }

        &-header,
        &-body,
        &-footer {
            padding: $voidNarrow;
        }

        &-header {
            .modal-title {

                .icon {
                    @if $displayMemberIcon==true {
                        display: inline-block;
                        padding-right: $voidDefault;

                        img {
                            max-height: $lineHeight*3;
                        }
                    }

                    @else {
                        display: none;
                    }
                }
            }
        }

        &-body,
        &-footer {
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;

            .basket-container {
                display: flex;
                flex-direction: column;
                width: 100%;
            }

            .sell-price {
                text-align: right;
                @include price-style;
            }
        }

        &-body {
            // max-height: $desktopModalContentHeight;
            // overflow-x: hidden;
            // overflow-y: scroll;

            .basket-container {

                .basket {

                    &-header .header-item,
                    &-body .basket-item {

                        display: grid;
                        grid-template-columns: 10% 10% auto auto auto 10% 10% 15%;
                        grid-gap: $voidDefault $voidDefault;
                        justify-items: stretch;
                        justify-content: stretch;
                        align-items: center;

                        @include phone {
                            font-size: smaller;

                            grid-gap: $voidNarrow $voidNarrow;
                            grid-template-columns: 15% 15% auto auto auto 15% 15% 20%;
                        }
                    }

                    &-header {

                        &.sticky-top {
                            width: 100%;
                            // position and z-index are redundant here (icluded in Bootstrap)
                            // position: fixed;
                            // z-index: $zindex-navbar-fixed;
                            background-color: $basketDefaultBgColor;

                            @if $enableAlternateBackgrounds==true {
                                background-color: $separatorBgColor;
                            }

                            padding-right: $basketBodyScrollBarWidth;

                            @include phone {
                                padding-right: 0;
                            }
                        }

                        .header-item {
                            grid-template-areas: ". title title title title unit-price promo-price ordered-price";

                            border-bottom: $borderDefault;

                            padding: {
                                top: $basketHeaderPadding;
                                bottom: $basketHeaderPadding;
                                left: $voidDefault;
                                right: $voidDefault;
                            }

                            .text {
                                font-size: $basketHeaderFontSize;
                                line-height: $basketHeaderFontSize;
                            }

                            .pic,
                            .qty,
                            .conditions,
                            .promotions,
                            .actions {
                                display: none;
                            }

                            .price {

                                &__unit,
                                &__promo,
                                &__ordered {
                                    text-align: right;
                                }
                            }
                        }
                    }

                    &-body {
                        .basket-item {
                            grid-template-areas: "image promotions promotions promotions promotions promotions promotions promotions"
                                "image title title title title title title title"
                                "image qty actions actions conditions unit-price promo-price ordered-price";

                            @include phone-and-tablet-vertical {
                                grid-template-areas: "image promotions promotions promotions promotions promotions promotions promotions"
                                    "image title title title title title title title"
                                    "image conditions conditions conditions conditions conditions conditions conditions"
                                    "image qty actions actions actions unit-price promo-price ordered-price";
                            }

                            &:first-child {
                                margin-top: $basketHeaderHeightCalculated;
                            }

                            border-bottom: $borderDefault;

                            &:last-child {
                                border-bottom: none;
                            }

                            padding: {
                                top: $voidDefault;
                                bottom: $voidDefault;
                                left: $voidDefault;
                                right: $voidDefault;
                            }

                            &.group-divider {
                                padding-top: $basketHeaderHeightCalculated * $goldenRatio;
                                font-weight: bold;
                                grid-template-areas: "title title title title title title title title";
                            }

                            &.last-added {
                                @if $hilightLastAddedProduct==true {
                                    background-color: $lastAddedBgColor;
                                    animation: blink-1 0.4s both;

                                    @keyframes blink-1 {

                                        0%,
                                        50%,
                                        100% {
                                            opacity: 1;
                                        }

                                        25%,
                                        75% {
                                            opacity: 0.25;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &-header .legend,
                    &-body .item {

                        // border: 1px solid red;

                        &.pic {
                            grid-area: image;
                        }

                        &.title {
                            grid-area: title;
                        }

                        &.promotions {
                            grid-area: promotions;

                            @import 'promo-labels';
                        }

                        &.qty {
                            grid-area: qty;
                        }

                        &.conditions {
                            grid-area: conditions;

                            @import 'promo-labels';
                        }

                        &.price {
                            &__unit {
                                grid-area: unit-price;
                            }

                            &__promo {
                                grid-area: promo-price;
                            }

                            &__ordered {
                                grid-area: ordered-price;
                            }

                            >* {
                                text-align: right;
                            }
                        }

                        &.actions {
                            grid-area: actions;

                            @include phone {
                                .btn {
                                    padding-left: .15rem;
                                    padding-right: .15rem;

                                    // .icon {}

                                    .text {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    &-header .legend {
                        @include phone {

                            &.pic,
                            &.actions,
                            &.title {
                                display: none;
                            }
                        }
                    }

                    &-body {

                        .basket-item {

                            .item {

                                &.title {
                                    @include heading-style;
                                }
                            }
                        }
                    }
                }
            }
        }

        &-footer {

            @if $totalsFirst==true {
                border-color: $borderColor;
            }

            @if $enableInnerShadow==true {
                box-shadow: $basketContentInnerShadow;
            }

            .basket-container {

                display: flex;
                flex-direction: column;

                .basket {
                    &-footer {
                        padding: $voidNarrow;

                        display: grid;
                        grid-template-columns: 24% 24% 24% 24%;
                        grid-gap: $voidNarrow 1%;
                        grid-template-areas: "total-discounts total-savings total-items total-amount";

                        @include phone {
                            grid-template-columns: 49% 49%;
                            grid-template-areas: "total-discounts total-savings"
                                "total-items total-amount";
                            grid-gap: $voidNarrow 2%;
                        }

                        .basket-total {

                            &__items,
                            &__savings,
                            &__discounts,
                            &__amount {

                                .legend,
                                .value {
                                    padding: $voidNarrow;

                                    @include phone {
                                        padding: $voidTiny;
                                        font-size: $phoneFontSize;
                                    }

                                    text-align: center;

                                    .sell-price {
                                        text-align: center;
                                    }
                                }

                                .legend {
                                    .text {
                                        font-size: smaller;
                                    }
                                }

                                // .value {
                                //     background-color: $basketDefaultBgColor;
                                // }
                            }

                            &__items {
                                grid-area: total-items;
                            }

                            &__savings {
                                grid-area: total-savings;
                            }

                            &__discounts {
                                grid-area: total-discounts;
                            }

                            &__amount {
                                grid-area: total-amount;

                                @if $hilightTotal==true {
                                    .value {
                                        border: $borderDefault;
                                        border-color: $colorPrimary;
                                    }
                                }
                            }

                            &__continue {
                                grid-area: button-continue;
                            }

                            &__checkout {
                                grid-area: button-checkout;
                            }

                            &__extra1 {
                                grid-area: button-extra-1;
                            }

                            &__clearbasket {
                                grid-area: button-clearbasket;
                            }
                        }

                        &.totals {

                            &.has-promotions,
                            &.has-discounts {

                                &__enabled {

                                    .basket-total {

                                        &__savings,
                                        &__discounts {
                                            display: block;
                                        }
                                    }
                                }
                            }

                            &.has-promotions {
                                &__disabled {

                                    .basket-total {

                                        &__savings {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            &.has-discounts {
                                &__disabled {

                                    .basket-total {

                                        &__discounts {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                        &.actions {

                            @if $totalsFirst==true {
                                order: 2;
                                border-top: $borderDefault;
                                border-color: $colorPrimary;
                            }

                            grid-template-areas: "button-clearbasket button-extra-1 button-continue button-checkout";

                            @include phone {
                                grid-template-areas: "button-clearbasket button-continue "
                                    "button-checkout button-checkout";
                            }

                            .btn {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }

            &.checkout-btn {
                &__disabled {
                    .basket-container {
                        .basket {
                            &-footer {
                                &.actions {
                                    grid-template-areas: "button-clearbasket . button-extra-1 button-continue";

                                    @include phone {
                                        grid-template-areas: "button-clearbasket button-continue";
                                        // "button-clearbasket button-extra-1";
                                    }

                                    .basket-total {
                                        &__checkout {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.has-promotions {
        &__enabled {
            .modal {
                &-body {
                    .basket-container {
                        .basket {
                            &-body {
                                .basket-item {
                                    .item {

                                        &.price {
                                            &__promo {
                                                color: $fallbackPromoColor;
                                                color: $promoColor;
                                                text-decoration: line-through;

                                                @if $hideInactivePromoPrice==true {
                                                    display: none;
                                                }
                                            }
                                        }
                                    }

                                    &.promo-price {
                                        &__active {
                                            .item {
                                                &.price {
                                                    &__unit {
                                                        text-decoration: line-through;
                                                    }

                                                    &__promo {
                                                        text-decoration: none;

                                                        @if $hideInactivePromoPrice==true {
                                                            display: inline;
                                                        }
                                                    }

                                                    &__ordered {
                                                        color: $fallbackPromoColor;
                                                        color: $promoColor;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &__disabled {
            .modal {
                &-body {
                    .basket-container {
                        .basket {
                            &-header {
                                .header-item {
                                    grid-template-areas: ". title title title title title unit-price ordered-price";

                                    .price {
                                        &__promo {
                                            display: none;
                                        }
                                    }
                                }
                            }

                            &-body {
                                .basket-item {
                                    grid-template-areas: "image . . . . . . ."
                                        "image title title title title title title title"
                                        "image qty actions actions actions actions unit-price ordered-price";

                                    &.group-divider {
                                        grid-template-areas: "title title title title title title title title";
                                    }

                                    .item {

                                        &.promotions,
                                        &.conditions {
                                            display: none;
                                        }

                                        &.price {
                                            &__promo {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.is-empty {
        .modal {
            &-footer {
                .basket-container {
                    .basket {
                        &-footer {
                            .basket-total {

                                &__clearbasket,
                                &__checkout {
                                    display: none;
                                }
                            }

                            &.actions {
                                grid-template-areas: ". . . button-continue";

                                @include phone {
                                    grid-template-areas: "button-continue button-continue";
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include phone {
    #popupBasketModal {
        .modal {
            &-body {
                max-height: $phoneModalContentHeight;
            }
        }
    }
}

@include phone-horizontal {
    #popupBasketModal {
        .modal {
            &-header {
                .modal-title {
                    .icon {
                        flex-basis: 5%;
                    }

                    .text {
                        flex-basis: 90%;
                    }

                    .actions {
                        flex-basis: 5%;
                    }
                }
            }

            &-body {
                max-height: $phoneLandscapeModalContentHeight;
            }

            &-footer {
                .basket-container {
                    .basket {
                        &-footer {
                            grid-template-columns: 24% 24% 24% 24%;
                            grid-gap: $voidTiny 1%;
                            grid-template-areas: "total-savings total-items total-discounts total-amount";
                        }
                    }
                }
            }
        }
    }
}

html {
    &.-webkit- {

        // iOS
        &.touch {
            #popupBasketModal {
                .modal {
                    &-body {
                        .basket-container {
                            .basket {
                                &-header {

                                    // Safari iOS fixed position scroll hack
                                    &.sticky-top {
                                        transform: translate3d(0, 0, 0);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}