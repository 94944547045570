/* lato-100 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/lato-v16-latin/lato-v16-latin-100.eot'); /* IE9 Compat Modes */
  src: local('Lato Hairline'), local('Lato-Hairline'),
       url('../fonts/lato-v16-latin/lato-v16-latin-100.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v16-latin/lato-v16-latin-100.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-100.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-100.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v16-latin/lato-v16-latin-100.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-300 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/lato-v16-latin/lato-v16-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Lato Light'), local('Lato-Light'),
       url('../fonts/lato-v16-latin/lato-v16-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v16-latin/lato-v16-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v16-latin/lato-v16-latin-300.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-100italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: url('../fonts/lato-v16-latin/lato-v16-latin-100italic.eot'); /* IE9 Compat Modes */
  src: local('Lato Hairline Italic'), local('Lato-HairlineItalic'),
       url('../fonts/lato-v16-latin/lato-v16-latin-100italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v16-latin/lato-v16-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-100italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v16-latin/lato-v16-latin-100italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-300italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/lato-v16-latin/lato-v16-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local('Lato Light Italic'), local('Lato-LightItalic'),
       url('../fonts/lato-v16-latin/lato-v16-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v16-latin/lato-v16-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-300italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v16-latin/lato-v16-latin-300italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-regular - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/lato-v16-latin/lato-v16-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Lato Regular'), local('Lato-Regular'),
       url('../fonts/lato-v16-latin/lato-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v16-latin/lato-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v16-latin/lato-v16-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/lato-v16-latin/lato-v16-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Lato Italic'), local('Lato-Italic'),
       url('../fonts/lato-v16-latin/lato-v16-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v16-latin/lato-v16-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v16-latin/lato-v16-latin-italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-700 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/lato-v16-latin/lato-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Lato Bold'), local('Lato-Bold'),
       url('../fonts/lato-v16-latin/lato-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v16-latin/lato-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v16-latin/lato-v16-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-700italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/lato-v16-latin/lato-v16-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
       url('../fonts/lato-v16-latin/lato-v16-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v16-latin/lato-v16-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v16-latin/lato-v16-latin-700italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-900italic - latin */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  src: url('../fonts/lato-v16-latin/lato-v16-latin-900italic.eot'); /* IE9 Compat Modes */
  src: local('Lato Black Italic'), local('Lato-BlackItalic'),
       url('../fonts/lato-v16-latin/lato-v16-latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v16-latin/lato-v16-latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-900italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v16-latin/lato-v16-latin-900italic.svg#Lato') format('svg'); /* Legacy iOS */
}
/* lato-900 - latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/lato-v16-latin/lato-v16-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Lato Black'), local('Lato-Black'),
       url('../fonts/lato-v16-latin/lato-v16-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/lato-v16-latin/lato-v16-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/lato-v16-latin/lato-v16-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/lato-v16-latin/lato-v16-latin-900.svg#Lato') format('svg'); /* Legacy iOS */
}




/* cabin-regular - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/cabin-v14-latin/cabin-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Cabin'), local('Cabin-Regular'),
       url('../fonts/cabin-v14-latin/cabin-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-regular.svg#Cabin') format('svg'); /* Legacy iOS */
}
/* cabin-500 - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/cabin-v14-latin/cabin-v14-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Cabin Medium'), local('Cabin-Medium'),
       url('../fonts/cabin-v14-latin/cabin-v14-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-500.svg#Cabin') format('svg'); /* Legacy iOS */
}
/* cabin-italic - latin */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/cabin-v14-latin/cabin-v14-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Cabin Italic'), local('Cabin-Italic'),
       url('../fonts/cabin-v14-latin/cabin-v14-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-italic.svg#Cabin') format('svg'); /* Legacy iOS */
}
/* cabin-500italic - latin */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/cabin-v14-latin/cabin-v14-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local('Cabin Medium Italic'), local('Cabin-MediumItalic'),
       url('../fonts/cabin-v14-latin/cabin-v14-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-500italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-500italic.svg#Cabin') format('svg'); /* Legacy iOS */
}
/* cabin-600 - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/cabin-v14-latin/cabin-v14-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Cabin SemiBold'), local('Cabin-SemiBold'),
       url('../fonts/cabin-v14-latin/cabin-v14-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-600.svg#Cabin') format('svg'); /* Legacy iOS */
}
/* cabin-600italic - latin */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/cabin-v14-latin/cabin-v14-latin-600italic.eot'); /* IE9 Compat Modes */
  src: local('Cabin SemiBold Italic'), local('Cabin-SemiBoldItalic'),
       url('../fonts/cabin-v14-latin/cabin-v14-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-600italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-600italic.svg#Cabin') format('svg'); /* Legacy iOS */
}
/* cabin-700italic - latin */
@font-face {
  font-family: 'Cabin';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/cabin-v14-latin/cabin-v14-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Cabin Bold Italic'), local('Cabin-BoldItalic'),
       url('../fonts/cabin-v14-latin/cabin-v14-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-700italic.svg#Cabin') format('svg'); /* Legacy iOS */
}
/* cabin-700 - latin */
@font-face {
  font-family: 'Cabin';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/cabin-v14-latin/cabin-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Cabin Bold'), local('Cabin-Bold'),
       url('../fonts/cabin-v14-latin/cabin-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/cabin-v14-latin/cabin-v14-latin-700.svg#Cabin') format('svg'); /* Legacy iOS */
}
/* noto-sans-regular - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans'), local('NotoSans'),
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-regular.svg#NotoSans') format('svg'); /* Legacy iOS */
}
/* noto-sans-italic - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans Italic'), local('NotoSans-Italic'),
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-italic.svg#NotoSans') format('svg'); /* Legacy iOS */
}
/* noto-sans-700 - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans Bold'), local('NotoSans-Bold'),
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-700.svg#NotoSans') format('svg'); /* Legacy iOS */
}
/* noto-sans-700italic - latin */
@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Noto Sans Bold Italic'), local('NotoSans-BoldItalic'),
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/noto-sans-v9-latin/noto-sans-v9-latin-700italic.svg#NotoSans') format('svg'); /* Legacy iOS */
}