@mixin basket-separator($position) {
    @include border-default($position);

    padding: {
        top: $voidDefault;
        bottom: $voidDefault;
        right: $voidDefault;
        left: $voidDefault;
    }

    @include phone {
        padding: {
            top: $voidNarrow;
            bottom: $voidNarrow;
            right: $voidNarrow;
            left: $voidNarrow;
        }
    }
}

@mixin basket-spacer($position) {
    @if $position==top {
        margin-top: $voidDefault * 2.5;
    }

    @else if $position==bottom {
        margin-bottom: $voidDefault * 2.5;
    }
}

@mixin basket-legend {
    font-size: $desktopFontSizeSmall;
    color: $priceLegendFallbackColor;
    color: $priceLegendColor;
}

@mixin basket-footer-number {
    @include price-style;
}