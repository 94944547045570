@import "css-variables";
@import 'holy-grail';
@import "fonts";
@import "het-wijnhuis-symbols";
@import "header";
@import "header-megasearch";
@import "header-megasearch-filters";
@import "home-styles";


// Overrides or missing BS4 features
.display- {

    &1,
    &2,
    &3,
    &4 {
        font-family: $font-family-sans-serif;
    }
}