#divProductCatalogue {
    &[data-catalogue-type="my-webshop"] {
        .product {
            flex: 0 0 100%; // display flex children as rows
            grid-template-columns: 16% 44% 40%;
            grid-template-areas:
                "image . ."
                "image title title"
                "image brand brand"
                "image origin origin"
                "image sell-area ."
                "image description description"
                "image extras extras"
                "image product-settings .";

            @include phone {
                grid-template-columns: 20% auto;
                grid-template-areas:
                    "image title"
                    "image origin"
                    "image brand"
                    "image description"
                    "image extras"
                    "image sell-area"
                    "product-settings product-settings";
            }

            &[data-winecard-status="active"] {
                @include debug(red);
            }

            .sell-area {
                .prices {
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: auto auto;
                    grid-template-areas:
                        "channel-price-area promo-price-area"
                        "promo-label-area promo-label-area";
                }
            }
        }
    }
}