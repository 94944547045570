// Reference devices
$iPhonePlus-min: 414px;
$iPhonePlus-max: 736px;

$iPhone8-min: 375px;
$iPhone8-max: 667px;

$iPhoneSE-min: 320px;
$iPhoneSE-max: 568px;

$iPhoneX-min: 375px;
$iPhoneX-max: 812px;

$iPad-min: 768px;
$iPad-max: 1024px;

$iPadPro10-min: 834px;
$iPadPro10-max: 1112px;

$iPadPro13-min: 1024px;
$iPadPro13-max: 1366px;

$fingerSafe-width: 57px;

// Breakpoints
$desktop-min: 1024px;

$tablet-min: $iPad-min;
$tablet-max: $iPad-max;

$phone-min: $iPhoneSE-min;
$phone-max: $iPhoneX-max;

@mixin desktop {
    @media (min-width: #{$desktop-min}) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: #{$tablet-max}) {
        @content;
    }
}

@mixin tablet-vertical {
    @media (max-width: #{$tablet-max}) and (orientation: portrait) {
        @content;
    }
}

@mixin tablet-horizontal {
    @media (max-width: #{$tablet-max}) and (orientation: landscape) {
        @content;
    }
}

@mixin phone {
    @media (max-width: #{$phone-max}) {
        @content;
    }
}

@mixin phone-vertical {
    @media (max-width: #{$phone-max}) and (orientation: portrait) {
        @content;
    }
}

@mixin phone-horizontal {
    @media (max-width: #{$phone-max}) and (orientation: landscape) {
        @content;
    }
}

@mixin phone-sm-vertical {
    @media (max-width: #{$phone-min}) and (orientation: portrait) {
        @content;
    }
}

@mixin not-phone {
    @media (min-width: #{$phone-max}) {
        @content;
    }
}

@mixin desktop-and-tablet {
    @media (min-width: #{$tablet-min}) {
        @content;
    }
}

@mixin phone-and-tablet-vertical {
    @media (max-width: #{$tablet-min}) {
        @content;
    }
}

@mixin using-mouse {
    @media (hover: hover) {
        @content;
    }
}

@mixin using-touch {
    @media (hover: none) {
        @content;
    }
}

@mixin container {
    @include make-container();
    @include make-container-max-widths();
}

@mixin shadow-sm {
    box-shadow: $box-shadow-sm !important;
}

@mixin shadow {
    box-shadow: $box-shadow !important;
}

@mixin shadow-lg {
    box-shadow: $box-shadow-lg !important;
}

@mixin shadow-none {
    box-shadow: none !important;
}

@mixin blurry-bg {
    backdrop-filter: blur(10px);

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }
}

@mixin border-default($position) {
    @if $likesBorders {
        @if $position==top {
            border-top: $borderWidth $borderStyle $borderColor;
        }

        @else if $position==bottom {
            border-bottom: $borderWidth $borderStyle $borderColor;
        }

        @else if $position==left {
            border-left: $borderWidth $borderStyle $borderColor;
        }

        @else if $position==right {
            border-right: $borderWidth $borderStyle $borderColor;
        }

        @else {
            border: $borderWidth $borderStyle $borderColor;
        }
    }

    @else {
        border: none;
    }

}

@mixin rounded($size) {
    @if $likesRoundedCorners {
        @if $size==lg {
            border-radius: $border-radius-lg;
        }

        @else if $size==sm {
            border-radius: $border-radius-sm;
        }

        @else {
            border-radius: $border-radius;
        }
    }
}

@mixin image-responsive {
    max-width: 100%;
    height: auto;
}

@mixin price-style {
    white-space: nowrap;
    font-family: $priceFontFamily;
    font-size: $priceFontSize;
    font-weight: $priceFontWeight;
}

@mixin price-style-big {
    white-space: nowrap;
    font-family: $priceFontFamily;
    font-size: $priceBigFontSize;
    font-weight: $priceFontWeight;
}

@mixin promo-price-color {
    color: $fallbackPromoColor;
    color: $promoColor;
}

@mixin promo-price-color-complementary {
    color: $fallbackPromoColorComplementary;
    color: $promoColorComplementary;
}

@mixin promo-price-bg-color {
    background-color: $fallbackPromoColor;
    background-color: $promoColor;
}

@mixin promo-price-bg-color-complementary {
    background-color: $fallbackPromoColorComplementary;
    background-color: $promoColorComplementary;
}

@mixin debug($color) {
    @if $DEBUG {
        border: 1px solid $color !important;
    }
}