@charset "UTF-8";

@font-face {
	font-family: "het-wijnhuis-symbols";
	src:url("../fonts/het-wijnhuis-symbols.eot");
	src:url("../fonts/het-wijnhuis-symbols.eot?#iefix") format("embedded-opentype"),
	url("../fonts/het-wijnhuis-symbols.woff") format("woff"),
	url("../fonts/het-wijnhuis-symbols.ttf") format("truetype"),
	url("../fonts/het-wijnhuis-symbols.svg#het-wijnhuis-symbols") format("svg");
	font-weight: normal;
	font-style: normal;

}

[data-icon]:before {
	font-family: "het-wijnhuis-symbols" !important;
	content: attr(data-icon);
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

[class^="hw-icon-"]:before,
[class*=" hw-icon-"]:before {
	font-family: "het-wijnhuis-symbols" !important;
	font-style: normal !important;
	font-weight: normal !important;
	font-variant: normal !important;
	text-transform: none !important;
	speak: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.hw-icon-screwcap:before {
	content: "b";
}
.hw-icon-karaf:before {
	content: "d";
}
.hw-icon-conica:before {
	content: "a";
}
.hw-icon-flute:before {
	content: "f";
}
.hw-icon-cork:before {
	content: "c";
}
.hw-icon-wijnglas:before {
	content: "e";
}
.hw-icon-cockade:before {
	content: "g";
}
.hw-icon-bio:before {
	content: "h";
}
