//
// SUNRISE SPECIFIC STYLES
// --------------------------------------------------

.sunrise-nav-bottom {
	margin-top: $grid-gutter-width;
	margin-bottom: $grid-gutter-width;
}

// Product Catalog (results listing)

.sunrise-product-catalog {

	&-image,
	&-content {}
}

.catalogue-item {
	margin-top: 2rem;
}

.fadeout {
	position: absolute;
	bottom: 0em;
	width: 100%;
	height: $font-size-base * 4;
	// Dont' forget to convert the .sunrise-product-catalog-item background-color to RGB for this gradient:
	// #gradient > .vertical($start-color: rgba(237,237,237,.0001); $end-color: rgba(237,237,237,1));
}

.sunrise-product-catalog-content {
	.content-title {
		font-size: $h4-font-size;
	}

	.content-desc {
		font-size: $font-size-sm;
	}
}

.product-description {
	margin-top: .5rem;
}

.sunrise-product-catalog-sell {
	padding-top: $font-size-base;
}

// Add/remove to favorites button
.addfav {
	.icon:before {
		content: "☆";
	}
}

.removefav {
	.icon:before {
		content: "★";
	}
}



//
//
// Product Detail

.sunrise-product-detail {
	&-image {
		padding-top: floor(($grid-gutter-width / 2)); // Same as .col-xx-Y padding (mixins.less)
		padding-bottom: floor(($grid-gutter-width / 2)); // Same as .col-xx-Y padding (mixins.less)
	}

	&-content {
		padding-top: $grid-gutter-width;

		h3 {
			color: $dark;
		}

		.content {

			&-title,
			&-desc {}
		}
	}
}

.sunrise-product-detail {
	&-footer {
		margin-top: $grid-gutter-width;
	}

	&-table {}
}

// Catalogue -narrow (product thumbnails)

.catalogue-row {

	h1,
	h2,
	h3 {
		&.prod {

			&-title,
			&-brand {
				margin: 0;
				font: $font-size-base/$line-height-base $headings-font-family;
			}
		}
	}
}

.prod {

	&-promos,
	&-price,
	&-buttons {
		margin-top: 5px;
		padding: 5px 0;
		min-height: ($line-height-base + 10px);
		// .clearfix();
	}

	&-promos {
		.label-promo {
			font: bold floor(($font-size-base * 0.9))/$line-height-base $font-family-sans-serif;
		}
	}
}



// Show available Stock

.stock-status {
	display: inline-block;
	min-height: 1em;
	margin: 0 $voidNarrow;
	padding: 0 $voidNarrow;
	// color: $white;
	// border: 0 solid transparent;
	// border-radius: $border-radius-sm;

	&.stock {

		&Low,
		&Normal,
		&High {
			min-width: 1rem;
		}

		&Low {
			// background-color: $danger;
			// border-color: $danger;
			color: $danger;
		}

		// &Normal {
		// 	background-color: $success;
		// 	border-color: $success;
		// }

		// &High {
		// 	background-color: darken($success, 20%);
		// 	border-color: darken($success, 20%);
		// }
	}

	// &.lowOrHighStockValueNotSet {}

	// .icon {}

	// .text {}
}



// Shopping Basket

.sunrise-basket {
	.panel {
		padding: 0;

		.panel {

			&-heading,
			&-body {
				padding: floor(($font-size-base / 2));
				border: none !important;
				background-color: transparent;
				box-shadow: none;
			}
		}

		&-group {
			margin-bottom: 0;
		}
	}

	.qtyInput {
		width: 40px;
		margin-top: 0px;
		float: left;
		height: 34px;
		text-align: center;
	}

	.item-service-links {}

	.text-success {
		color: $brand-promo;
		font-weight: bold;
	}

	.sunrise-basket {
		&-totals {
			background-color: $light;
		}
	}

	.sunrise-basket-header-desc {
		font-size: 0.7em;
		padding-bottom: 0.35em;
		white-space: nowrap;
		color: $text-muted;
	}

	td {
		&.valign-middle {
			vertical-align: middle;
		}
	}

	&-nav {
		margin-top: $line-height-base;
		margin-bottom: $line-height-base;
	}

	&-footer {
		margin-top: ($line-height-base * 3);
		padding: 0 15px;
		border: 1px solid $gray-200;
		background-color: $light;
		border-radius: $border-radius;

		.basket {

			&-subtotals,
			&-shipping,
			&-totals {
				padding: 15px 0;
			}

			&-shipping,
			&-totals {
				border-top: 1px solid $gray-200;
			}
		}
	}
}

.basket-prod-title {
	font-size: $font-size-base;
}



// Sunrise Search Engine

.sunrise-search-engine {
	&.jumbotron {
		padding: floor(($jumbotron-padding / 3));
		margin-bottom: 0;
	}

	.search-keyword {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.sunrise-keywords-form {
	.input-group-addon {
		padding-top: 0;
		padding-bottom: 0;
		border: none;

		.btn {
			padding-top: 0;
			padding-bottom: 0;
		}

		.btn-link {
			padding: 0;
		}
	}

	.sunrise-keywords-input-bar {
		border: none;
	}

	&>.form-group {
		margin-bottom: 0;
	}
}


// Brands sections to display one brand per line

.brand {
	&-section {
		margin-top: ($line-height-base * 2);
	}

	&-container,
	&-row {}
}


// Brands page sidebar navigation
// (works together with Affix Plugin)
// Ref: http://getbootstrap.com/javascript/#affix
// E.G.:
// <ul id="sidebar" class="nav nav-stacked" data-spy="affix" data-offset-top="400" data-offset-bottom="80">

.brandgroups-container {
	margin-top: $lineHeight*3;
}

.brands-page-wrapper {
	.brands-page {
		&--aside {
			.brands-page-nav {
				font-size: $desktopFontSizeSmall;

				.nav-link {
					padding: $voidNarrow $voidDefault;
				}

				.nav-header,
				.back-to-top {
					font-weight: $headings-font-weight;
				}
			}
		}

		&--content {
			.brand-region-header {
				margin-top: $lineHeight*2;
			}

			.section {
				&.brand-section {
					margin-top: $lineHeight*2;
				}
			}

			.brand-container {

				.brand-row {
					.brand {

						// &-logo,
						// &-title,
						// &-desc {}

						&-title {
							font-size: $desktopFontSizeMedium;
							// .brand-short-desc {}
						}
					}

					// .brand-products-link {
					// 	.counter {}
					// }

					// .brand-page-link {}
				}
			}
		}
	}
}



//Brandgroup Menu ($SUNRISE_BRANDGROUPMENU)
#brandgroupmenu {
	.brandgroup {

		&-item,
		&-name {}
	}
}

// Brandgroups Icons Menu ($SUNRISE_BRANDGROUPICONMENU)

#brandgroupiconmenu {
	padding: $mark-padding 0;
	text-align: center;

	.brandgroup {
		&-item {
			vertical-align: top;
			width: $grid-gutter-width;
		}

		&-image {}

		&-name {
			font-size: $font-size-sm;
			font-weight: bold;
			display: block;
			padding: $mark-padding 0;
		}
	}
}


// Promo ribbon

.ribbon-wrapper {
	width: 85px;
	height: 88px;
	overflow: hidden;
	position: absolute;
	top: 0;
	right: 15px;

	.ribbon {
		font: bold 12px sans-serif;
		text-align: center;
		transform: rotate(45deg);
		position: relative;
		padding: 5px 0;
		left: 0;
		top: 15px;
		width: 120px;

		&.promo {
			color: #fff;
			background-color: $brand-promo;
		}
	}
}


// Favourite wines
.add-to-favorites {

	// hide add to favorites button text
	@if $favoriteBtnWithText !=true {
		.text {
			display: none !important;
		}
	}
}


// Hide border and box shadow when needed
.noborder {
	border: none !important;
	box-shadow: none !important;
}


// Sunrise and DOTNET fixes

// #form1, .aspNetHidden, .emptyContentHeader, .emptyContentContainer {
//   .hidden;
// }


//
// USER COMMON Sidebar navigation
@media screen and (max-width: 768px) {
	.row-offcanvas {
		position: relative;
		-webkit-transition: all 0.25s ease-out;
		-moz-transition: all 0.25s ease-out;
		transition: all 0.25s ease-out;

		&-left {
			.sidebar-offcanvas {
				left: -40%;
			}

			&.active {
				left: 40%;
			}
		}
	}

	.sidebar-offcanvas {
		position: absolute;
		top: 0;
		width: 40%;
		margin-right: $mark-padding;
	}

	.nav-sidebar>li,
	.sidebar-header {
		padding-left: floor(($mark-padding / 2));
	}

	.nav-sidebar {
		li {
			a {
				&.sidebar-link {
					padding-left: floor(($mark-padding * 1.5));
				}
			}
		}
	}
}

.nav-sidebar {
	background-color: $gray-200;
	margin-right: -$mark-padding;
	margin-bottom: $mark-padding;
	margin-left: -$mark-padding;
	border-radius: $border-radius;

	&>li {
		display: block;

		&>a {
			padding-right: $mark-padding;
			padding-left: $mark-padding;
		}

		&>.active {
			&>a {
				font-weight: $headings-font-weight;
				color: $dropdown-link-active-color;
				background-color: $dropdown-link-active-bg;
			}
		}
	}
}

.sidebar-header {
	font-family: $headings-font-family;
	font-weight: $headings-font-weight;
	font-size: $font-size-base;
	line-height: $headings-line-height;
	color: $headings-color;
	margin-top: $line-height-base;
	margin-bottom: floor(($line-height-base / 2));
}