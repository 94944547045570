html {
    scroll-behavior: smooth;
}

// Holy Grail layout with footer that sticks to bottom
body {
    min-height: 100vh;

    #wrapper {
        min-height: 100vh;

        #sunpage,
        #catalogue,
        #container {
            display: flex;
            min-height: 100vh;
            flex-direction: column;

            #divHeader,
            #cphContainer_divFooter {
                flex: none;
            }
        }

        #divHeader+* {
            padding-top: var(--header-height-calculated);
        }

        #sunpage-content,
        #catalogue-content,
        #contentbox {
            display: flex;
            flex: 1 0 auto;
            flex-direction: column;
        }
    }
}

// ./Holy grail