@import 'basket-mixins';


// Render stylesheet only on dev build type
// Already included in webshop application (public.min.css)
// @if $buildType=='dev' {


.checkout-basket {
    display: flex;
    flex-direction: column;

    .basket {

        &--header,
        &--body,
        &--footer,
        &--actions {
            padding: {
                top: 0;
                bottom: $voidDefault;
                left: $voidFlex;
                right: $voidFlex;
            }
        }

        &--header {

            // &::before {
            //     border: 1px solid red;
            //     padding: 0 2rem;
            //     content: 'Build: '+ $buildType;
            // }

            .header {

                &-title {
                    // Basket header text (V9 template)
                    padding-top: $voidDefault * 3;
                }

                &-comments {
                    // Basket comment text (V9 template)
                    padding: $voidDefault 0;
                }
            }
        }

        &--body {
            .products {
                .products-group {
                    &--title {

                        font-weight: bold;

                        @include basket-spacer(top);
                        @include basket-separator(bottom);
                    }

                    &--body {
                        .product {
                            display: grid;
                            grid-template-columns: 10% auto auto auto auto auto 10% 10% 10% 10%;
                            grid-template-rows: auto;
                            grid-gap: $voidDefault $voidDefault;
                            justify-items: stretch;
                            justify-content: stretch;
                            align-items: end;
                            grid-template-areas: "image promotions promotions promotions promotions promotions promotions promotions promotions promotions"
                                "image title title title title title title title title title"
                                "image description description description description description description description description description"
                                "image actions actions conditions conditions conditions qty unit-price promo-price ordered-price";

                            @include phone {
                                grid-template-columns: 25% auto 18% 18% 25%;
                                grid-gap: $voidNarrow $voidNarrow;
                                grid-template-areas: "image promotions promotions promotions promotions"
                                    "image title title title title"
                                    "image description description description description"
                                    "image actions actions conditions conditions"
                                    "qty . unit-price promo-price ordered-price";
                            }

                            @include basket-separator(bottom);
                            @include rounded(md);

                            &:hover {
                                @if $hilightBasketProductOnHover==true {
                                    background-color: $highlightOnHoverFallbackColor;
                                    background-color: $highlightOnHoverColor;
                                }
                            }

                            .item {

                                // border: 1px solid red;

                                &.pic {
                                    grid-area: image;

                                    img {
                                        @include image-responsive;
                                    }
                                }

                                &.title {
                                    grid-area: title;

                                    font-size: $desktopFontSizeMedium;
                                    font-weight: bold;

                                    @include phone {
                                        font-size: $desktopFontSize;
                                    }
                                }

                                &.description {
                                    grid-area: description;

                                    font-size: $desktopFontSizeSmall;
                                }

                                &.promotions {
                                    grid-area: promotions;

                                    @import 'promo-labels';
                                }

                                &.qty {
                                    grid-area: qty;
                                }

                                &.conditions {
                                    grid-area: conditions;

                                    @import 'promo-labels';
                                    text-align: center;
                                }

                                &.price {

                                    .content {
                                        @include price-style;
                                    }

                                    &.unit {
                                        grid-area: unit-price;
                                    }

                                    &.promo {
                                        grid-area: promo-price;

                                        .content {
                                            @include promo-price-color;
                                            text-decoration: line-through;
                                        }
                                    }

                                    &.ordered {
                                        grid-area: ordered-price;
                                    }

                                    >* {
                                        text-align: right;
                                    }
                                }

                                &.actions {
                                    grid-area: actions;
                                }

                                &.qty,
                                &.price {
                                    display: flex;
                                    flex-direction: column;

                                    .legend {
                                        @include basket-legend;
                                    }

                                    .content {
                                        @include price-style;
                                    }
                                }

                                &.qty {
                                    .legend {
                                        text-align: center;
                                    }
                                }

                                &.price {
                                    .legend {
                                        text-align: right;
                                    }
                                }
                            }

                            &.promo-price {

                                &__inactive {

                                    .item {
                                        &.price {

                                            &.promo {

                                                @if $hideInactivePromoPrice==true {
                                                    display: none;
                                                }

                                                .content {
                                                    text-decoration: line-through;
                                                }
                                            }
                                        }
                                    }
                                }

                                &__active {

                                    .item {
                                        &.price {
                                            &.unit {
                                                .content {
                                                    text-decoration: line-through;
                                                }
                                            }

                                            &.promo {

                                                @if $hideInactivePromoPrice==true {
                                                    display: block;
                                                }

                                                .content {
                                                    @include promo-price-color;
                                                    text-decoration: none;
                                                }
                                            }

                                            &.ordered {
                                                .content {
                                                    @include promo-price-color;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &.bundle-of-products {
                                grid-template-columns: 20% auto auto auto auto auto 10% 10% 10% 10%;

                                .item {
                                    .bundles-list {
                                        .legend {
                                            margin-top: $voidNarrow;
                                            margin-bottom: $voidNarrow;
                                            font-weight: bold;
                                        }

                                        .items {
                                            list-style: none;
                                            padding: 0;

                                            .bundle-item {
                                                margin-bottom: $voidNarrow;

                                                .bundle-item {
                                                    &-qty {
                                                        margin-right: $voidNarrow;
                                                    }

                                                    // &-name {}
                                                }
                                            }
                                        }
                                    }
                                }

                                @include phone {
                                    grid-template-columns: 8% repeat(4, 1fr);
                                    grid-template-areas: "title title title title title"
                                        "image image image image image"
                                        "promotions promotions promotions promotions promotions"
                                        "description description description description description"
                                        "actions actions conditions conditions conditions"
                                        "qty qty unit-price promo-price ordered-price";

                                    .item {
                                        &.pic {
                                            text-align: center;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &--footer {
            .footer {

                display: flex;
                flex-direction: column;

                &-subtotals,
                &-codes,
                &-shipping-methods,
                &-totals {
                    display: flex;
                    justify-content: flex-end;
                    align-items: stretch;

                    background-color: $basketFooterFallbackBgColor;
                    background-color: $basketFooterBgColor;

                    border: $borderDefault;

                    margin-bottom: $voidDefault;
                }

                &-subtotals {

                    .subtotal {

                        &-qty,
                        &-gross,
                        &-savings,
                        &-order {

                            // border: 1px solid red;

                            flex-basis: 15%;

                            display: flex;
                            flex-direction: column;

                            padding: {
                                top: $voidDefault;
                                right: $voidDefault;
                                bottom: $voidDefault;
                                left: 0;
                            }

                            @include phone {
                                flex-basis: 25%;

                                padding: {
                                    top: $voidNarrow;
                                    right: $voidNarrow;
                                    bottom: $voidNarrow;
                                    left: $voidNarrow;
                                }
                            }

                            .legend,
                            .content {
                                // border: 1px solid red;
                                text-align: right;
                                padding: $voidNarrow;
                            }

                            .legend {
                                @include basket-legend;

                                border-bottom: $borderDefault;
                                // margin-bottom: $voidNarrow;
                            }

                            .content {
                                @include basket-footer-number;
                            }
                        }

                        &-savings {
                            .content {
                                @include promo-price-color;
                            }
                        }

                        &-order {
                            .content {
                                background-color: $basketDefaultBgColor;
                            }
                        }
                    }

                    &.has-savings {
                        &__disabled {
                            .subtotal {

                                &-gross,
                                &-savings {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                &-codes {
                    flex-direction: column;

                    .code-container {
                        display: grid;
                        grid-template-columns: 15% 20% auto 25% 15%;
                        grid-gap: $voidDefault $voidDefault;
                        padding: $voidDefault;
                        justify-items: stretch;
                        justify-content: stretch;
                        align-items: start;
                        grid-template-areas: "code-toggle code-input code-actions code-desc code-result"
                            ". code-feedback code-feedback . .";

                        @include phone {
                            grid-template-columns: 15% auto 35%;
                            grid-gap: $voidNarrow $voidNarrow;
                            padding: $voidNarrow;
                            grid-template-areas: "code-toggle code-toggle code-toggle"
                                "code-input code-input code-input"
                                "code-feedback code-feedback code-feedback"
                                "code-actions code-actions code-actions"
                                "code-desc code-desc code-result";
                        }

                        .code {

                            // &--toggle,
                            // &--input,
                            // &--feedback,
                            // &--actions,
                            // &--description,
                            // &--result {
                            //     border: 1px solid red;
                            // }

                            &--toggle {
                                grid-area: code-toggle;
                            }

                            &--input {
                                grid-area: code-input;

                                input {
                                    text-align: center;
                                    text-transform: uppercase;
                                }
                            }

                            &--feedback {
                                grid-area: code-feedback;
                            }

                            &--actions {
                                grid-area: code-actions;
                            }

                            &--description {
                                grid-area: code-desc;
                                text-align: right;
                            }

                            &--result {
                                grid-area: code-result;
                                padding-left: $voidNarrow;

                                .legend,
                                .content {
                                    text-align: right;
                                    padding: $voidNarrow;
                                }

                                .legend {
                                    @include basket-legend;
                                    border-bottom: $borderDefault;
                                }

                                .content {
                                    @include basket-footer-number;
                                    background-color: $basketDefaultBgColor;
                                    @include promo-price-color;
                                }
                            }
                        }

                        &+.code-container {
                            @include basket-separator(top);
                        }

                        &.code-status {
                            &__inactive {
                                grid-template-columns: auto;
                                grid-template-areas: "code-toggle";

                                .code {

                                    &--input,
                                    &--feedback,
                                    &--actions,
                                    &--description,
                                    &--result {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }

                &-shipping-methods {
                    flex-direction: column;

                    .shipping-container {
                        display: grid;
                        grid-template-columns: 25% 45% auto 15%;
                        grid-gap: $voidDefault $voidDefault;
                        padding: $voidDefault;
                        justify-items: stretch;
                        justify-content: stretch;
                        align-items: start;

                        grid-template-areas: "shipping-country shipping-method . shipping-cost";

                        @include phone {
                            grid-template-columns: auto 25%;
                            grid-gap: $voidNarrow $voidNarrow;
                            padding: $voidNarrow;
                            grid-template-areas: "shipping-country shipping-country"
                                "shipping-method shipping-method"
                                ". shipping-cost";
                        }

                        .shipping {

                            &--country,
                            &--method,
                            &--cost {
                                // border: 1px solid red;

                                .legend,
                                .content {
                                    padding: $voidNarrow;
                                }

                                .legend {
                                    @include basket-legend;
                                    border-bottom: $borderDefault;
                                }
                            }

                            &--country {
                                grid-area: shipping-country;
                            }

                            &--method {
                                grid-area: shipping-method;
                            }

                            &--cost {
                                grid-area: shipping-cost;

                                .legend,
                                .content {
                                    text-align: right;
                                }

                                .content {
                                    @include basket-footer-number;
                                    background-color: $basketDefaultBgColor;
                                }
                            }
                        }

                        &.status {
                            &__has-free-shipping {
                                .shipping {
                                    &--cost {
                                        .content {
                                            @include promo-price-color;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &-totals {
                    flex-direction: column;

                    .totals-container {
                        display: grid;
                        grid-template-columns: auto 15%;
                        grid-gap: $voidDefault $voidDefault;
                        padding: $voidDefault;
                        justify-items: stretch;
                        justify-content: stretch;
                        align-items: end;

                        @include phone {
                            grid-template-columns: auto 37%;
                            grid-gap: $voidNarrow $voidNarrow;
                            padding: $voidNarrow;
                        }

                        .grand-total {

                            &--description,
                            &--value {

                                .legend,
                                .content {
                                    padding: $voidNarrow;
                                    text-align: right;
                                }

                                .legend {
                                    @include basket-legend;
                                    border-bottom: $borderDefault;
                                }
                            }

                            &--description {
                                font-weight: bold;
                                font-size: $desktopFontSizeMedium;
                            }

                            &--value {

                                .content {
                                    @include basket-footer-number;
                                    background-color: $basketDefaultBgColor;
                                    border: $borderDefault;
                                }
                            }
                        }
                    }
                }
            }
        }

        &--actions {

            display: flex;
            flex-direction: row-reverse;
            justify-content: flex-start;
            align-items: stretch;
            align-content: flex-start;

            @include phone {
                flex-direction: column;
            }

            .action {

                &-checkout,
                &-save {
                    flex-basis: 33%;
                    padding-right: $voidDefault;

                    @include phone {
                        flex-basis: 100%;
                        padding-right: 0;
                        padding-bottom: $voidNarrow;
                    }
                }
            }
        }
    }

    &.has-promotions {
        &__disabled {
            .basket {
                &--body {
                    .products {
                        .products-group {
                            &--body {
                                .product {
                                    grid-template-areas: "image . . . . . . . . ."
                                        "image title title title title title title title title title"
                                        "image description description description description description description . . ."
                                        "image actions actions actions actions actions actions qty unit-price ordered-price";

                                    @include phone {
                                        grid-template-columns: repeat(3, 1fr);
                                        grid-template-areas:
                                            "image title title"
                                            "image description description"
                                            "image actions actions"
                                            "qty unit-price ordered-price";
                                    }

                                    .item {

                                        &.promotions,
                                        &.conditions {
                                            display: none;
                                        }

                                        &.price {
                                            &.promo {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // &.has-discounts {
    //     &__disabled {}

    //     &__enabled {}
    // }
}

// }

// ./if