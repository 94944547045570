//
//
// checkout.aspx

.checkout-wrapper,
.basket-summary-wrapper {
    .value {
        white-space: nowrap;

        &.p {
            &-qty {
                font-weight: bold;
            }
        }
    }

    .sell-price {
        font-size: inherit;
    }
}