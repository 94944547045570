:root {
    --color-white: #fff;
    --color-red: #990524;
    --color-blue: #000e72;
    --color-green: #008a00;
    --color-green-dark: #00543c;
    --color-orange: #ea8c2c;
    --color-yellow: #ffee00;
    --color-light: #eee;
    --color-dark: #313332;
    --color-gray: #a19e97;
    --color-gray-light: #ccc;
    --color-gray-lighter: #eee;
    --color-gray-dark: #4a4c4b;
    --color-gray-darker: #313332;

    --color-primary: #990524;
    --color-secondary: var(--color-light);
    --color-group-promo: #eb8526;

    --color-b2b-party: var(--color-red);
    --color-b2b-classic: var(--color-blue);
    --color-b2b-discovery: var(--color-orange);
    --color-b2b-thinkbig: #375A00;

    --font-size-base: calc(10px + .4vmax);
    --font-size-big: calc(var(--font-size-base) * 1.75);
    --font-size-medium: calc(var(--font-size-base) * 1.2);
    --font-size-small: calc(var(--font-size-base) * .8);
    --font-size-label: calc(var(--font-size-base) * .9);
    --font-size-promo: var(--font-size-base);

    --word-spacing: .5rem;

    --shadow-h-offset: 0px;
    --shadow-v-offset: 2000px;
    --shadow-blur: 0;
    --shadow-spread: 2000px;
    --shadow-color: rgba(0, 0, 0, 0.65);
    /* --shadow-height-calculated: calc(var(--shadow-v-offset) + var(--shadow-blur) + var(--shadow-spread)); */
    --shadow-height-calculated: 32px;

    --logo-desktop-width: 30%;
    --logo-tablet-width: 10%;
    --logo-phone-width: 18%;

    --header-main-bgcolor: var(--color-gray-lighter);
    --header-main-color: var(--color-gray-dark);
    --header-serv-bgcolor: var(--color-gray-light);
    --header-serv-color: var(--color-white);
    --header-search-bgcolor: var(--color-gray-lighter);
    --header-search-color: var(--color-gray-dark);

    --header-font-size: var(--font-size-base);
    --header-line-height: calc(var(--header-font-size) * 1.25);
    --header-padding-vertical: calc(var(--header-font-size) * .5);
    --header-padding-horizontal: 0;

    --header-border-color: var(--color-gray-lighter);
    --header-border-radius: calc(var(--header-font-size) * 2);
    --header-border-radius-small: calc(var(--font-size-small) / 2);
    --header-empty-space-vertical: calc(var(--header-font-size) / 5);
    --header-empty-space-horizontal: calc(var(--header-empty-space-vertical) * 1.63);

    --header-height-calculated: 120px;

    --header-head-flex-basis: 25%;
    --header-body-flex-basis: 75%;
    --navbar-menu-default-width: 25%;

    --header-tablet-head-flex-basis: 10%;
    --header-tablet-body-flex-basis: 90%;
    --navbar-menu-tablet-default-width: 33%;

    --header-phone-head-flex-basis: 15%;
    --header-phone-body-flex-basis: 85%;
    --navbar-menu-phone-default-width: 100%;

    --header-main-font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, system-ui, sans-serif;
    --header-main-font-size: var(--font-size-medium);
    --header-main-line-height: var(--header-main-font-size);
    --header-main-padding-vertical: calc(var(--header-main-font-size) * 1.5);
    --header-main-padding-horizontal: calc(var(--header-main-font-size) * 1.75);

    --navbar--font-size: var(--font-size-base);
    --navbar--line-height: var(--navbar--font-size);
    --navbar--font-weight-normal: 400;
    --navbar--font-weight-bold: 700;

    --navbar-color: var(--header-main-color);
    --navbar-bg-color: var(--header-main-bgcolor);
    --navbar-color__hover: var(--color-primary);
    --navbar-bg-color__hover: var(--color-gray-lighter);
    --navbar--dark--color: var(--color-gray-light);
    --navbar--dark--bg-color: var(--color-gray-dark);
    --navbar--dark--color__hover: var(--navbar-bg-color__hover);
    --navbar--dark--bg-color__hover: var(--navbar-color__hover);

    --navbar-link-color: var(--navbar-color);
    --navbar-link-bg-color: var(--navbar-bg-color);
    --navbar-link-color__hover: var(--color-white);
    --navbar-link-bg-color__hover: var(--color-primary);
    --navbar--dark--link-color: var(--color-gray-light);
    --navbar--dark--link-bg-color: var(--color-gray-dark);
    --navbar--dark--link-color__hover: var(--color-white);
    --navbar--dark--link-bg-color__hover: var(--color-primary);
    --navbar--link-font-weight: var(--navbar--font-weight-normal);

    --navbar--active--bg-color: var(--color-primary);
    --navbar--active--color: var(--color-gray-light);
    --navbar--active--bg-color__hover: var(--color-primary);
    --navbar--active--color__hover: var(--color-white);

    --navbar-dark-user-accent-color: var(--color-white);

    --navbar-border-size: 1px;
    --navbar-border-style: solid;
    --navbar-border-color: var(--color-gray-light);
    --navbar--void-vertical: calc(var(--navbar--font-size) * .75);
    --navbar--void-horizontal: calc(var(--navbar--font-size) * .95);
    --navbar--void-small-vertical: 2px;
    --navbar--void-small-horizontal: 1rem;

    --navbar-megasearch-max-width: 80rem;
}