// Show/hide elements with @SUNRISE_SUPERCUSTOMER_INFO(ENABLE_*)
.display,
.common-display {
    &__off {
        display: none !important;
    }

    &__on {
        display: initial;
    }
}


// banner that appears when a supercustomer is making orders on behalf of a customer of his/hers
// (var isLoggedInAsSupercustomer = true)
.superuser-banner {
    padding: $voidNarrow;
    margin: $voidNarrow;
    overflow: hidden;
    z-index: 10000;
    pointer-events: none;

    bottom: 0;
    left: 0;
    position: fixed;

    background: rgba(255, 217, 46, 0.45);
    @include blurry-bg;

    display: grid;
    justify-items: stretch;
    align-items: center;
    grid-template-columns: auto 1fr;
    grid-template-areas: "superuser superuser"
    "title title"
    "customer session";

    .superuser {

        &--real-contact,
        &--title,
        &--persona {
            color: #000;
            text-align: center;
            // .icon {}
            // .text {}
        }

        &--real-contact {
            grid-area: superuser;
        }

        &--title {
            grid-area: title;
        }

        &--persona {
            grid-area: customer;
            margin-top: $voidNarrow;
        }

        &--session {
            grid-area: session;
        }

        &--real-contact,
        &--title {
            font-size: .75em;
        }

        &--real-contact,
        &--persona {
            .text {
                font-weight: $font-weight-bold;
            }
        }

        &--session {
            margin-top: $voidNarrow;
            margin-left: $voidNarrow;

            .text {
                display: none;
            }

            a {
                pointer-events: auto;
            }
        }
    }
}