//
//
// PRICES AND PROMO TEXT APPEARANCE
//


.group-promo-text {
	// background-color: $btn-primary-bg;
	color: #fff;
	padding: 0 floor((($table-cell-padding / 2)));
	border: 1px solid transparent;
	// border-radius: $border-radius-large;
	white-space: nowrap;
	display: inline-block;
}

.sell-price {
	@include price-style;
}

.sell-total {
	@include price-style-big;
}

.promo-price,
.group-promo-price {
	color: $brand-promo;
}

.sell-total {
	color: $price-total-color;
}

.sunrise-product-detail-content .sell-price {
	font-size: $h2-font-size;
}

.sunrise-product-catalog-sell .sell-price,
.sunrise-product-detail-content .sell-price {
	font-size: $font-size-lg;
	color: $dark;
}

.label-price,
.text-promo {
	font-family: $price-font-family;
}

.label-success.label-promo {
	font-size: 0.95em;
}

.row-promo-prices .promo-price-qty {
	font-size: 0.85em;
}

.row-promo-prices .sell-price,
.sunrise-product-detail .row-promo-prices .sell-price {
	font-size: floor(($price-font-size * 0.95));
}