//
//
// Webshop catalogue with grid layout

@mixin catalogue-flex-basis {
  $columnVoid: 0;

  @if $catalogueResultsCols>1 {
    $columnVoid: $voidFlex;
  }

  flex-basis: (100% / $catalogueResultsCols) - $columnVoid;
}

.catalogue-row,
.bundle-product-contents {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;

  padding: ($lineHeight * 3) 0;

  @include debug(cyan);

  &.row {
    margin: 0;
  }

  &.bundle-product-contents {
    padding-top: 0;

    .page-header {
      margin-top: $voidDefault;
    }
  }

  .product {
    @include debug(blue);

    @include catalogue-flex-basis();
    margin-bottom: $lineHeight * 3;
  }

  // weird margin left fix for first child inside flex container
  // &> :first-child {
  //   margin-left: -$voidFlex * 2;
  // }
}

.bundle-of-products--heading {
  @include font-size($display4-size);
  font-weight: $display4-weight;
  line-height: $display-line-height;
}

.bundle-product-contents {
  padding-left: $bootstrapContainerPadding;
  padding-right: $bootstrapContainerPadding;
}


.product {

  // background-color: lightyellow;
  // border-bottom: 1px solid #444;
  &>* {
    @include debug(#444);
  }

  display: grid;
  grid-template-columns: 25% auto;
  grid-gap: $voidDefault $voidDefault;
  justify-items: stretch;
  justify-content: stretch;

  grid-template-areas: "image title"
  "image brand"
  "image origin"
  "image sell-area"
  "image product-settings"
  "image description"
  "image extras";

  // .title,
  // .brand,
  // .origin,
  // .image,
  // .description,
  // .sell-area,
  // .product-settings,
  // .extras {
  //   background-color: rgba($color: #FFCA69, $alpha: 0.15);
  //   border-bottom: 1px dotted #444;
  // }

  .title {
    grid-area: title;
  }

  .brand {
    grid-area: brand;
  }

  .origin {
    grid-area: origin;

    font-weight: $font-weight-bold;
  }

  .image {
    grid-area: image;
  }

  .description {
    grid-area: description;
  }

  .extras {
    grid-area: extras;

    a {
      text-decoration: underline;
    }
  }

  .title {
    .main {
      @include heading-style;
      font-size: $desktopFontSizeMedium;
    }
  }

  .brand {
    a {
      text-decoration: underline;
    }
  }

  .sell-area {
    grid-area: sell-area;

    display: flex;
    flex-direction: column;

    .prices {
      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: ($lineHeight * 3) ($lineHeight * 2);
      grid-template-areas: "channel-price-area promo-price-area"
        "promo-label-area promo-label-area";
      justify-content: space-around;

      .channel-price {
        grid-area: channel-price-area;
      }

      .channel-promo-price,
      .group-promo-price {
        grid-area: promo-price-area;
      }

      .group-label {
        grid-area: promo-label-area;
        @import 'promo-labels';
      }

      .channel-price,
      .channel-promo-price,
      .group-promo-price,
      .winecard-price {
        .value {
          @include price-style;
        }

        .extra {
          font-weight: inherit;
        }
      }

      .channel-promo-price,
      .group-promo-price {
        color: $promoColor;
      }
    }

    .actions {
      margin-top: $voidFlex;

      .product-do,
      .btn-group {
        // display: flex;
        flex-flow: row nowrap;

        // button,
        // .btn {

        //   &.add-to-basket {
        //     border-top-right-radius: 0;
        //     border-bottom-right-radius: 0;
        //   }

        //   &.add-to-favorites {
        //     border-top-left-radius: 0;
        //     border-bottom-left-radius: 0;
        //     border-left: none;
        //   }
        // }

        .add-to-basket,
        .add-to-favorites,
        .edit-winecard-btn {

          display: flex;
          align-items: center;

          // .icon,
          // .text {
          //   display: inline-block;
          // }

          // .icon {
          //   &+.text {
          //     padding-left: $wordSpacing;
          //   }
          // }
        }
      }

      .product-do {
        display: flex;
        align-items: center;

        .btn {
          margin-right: $voidNarrow;
        }

        .edit-winecard-btn {
          margin-right: 0;
          margin-left: auto;
        }
      }
    }
  }

  .product-settings {
    grid-area: product-settings;

    .product-settings {
      &--stock {
        .show-as {

          // &__value,
          // &__class {
          //   .stock {
          //     &-display {}

          //     &-expected {}
          //   }
          // }

          &__value {
            display: flex;
            justify-content: space-between;

            .stock {
              &-display {
                white-space: nowrap;
              }

              &-expected {
                font-size: smaller;
                text-align: right;
              }
            }
          }

          // &__class {
          //   // check in sunrise.scss
          //   .stock-status {}
          // }
        }
      }
    }
  }


  &.product-detail {
    flex-basis: 100%;
    grid-template-columns: auto (100% / $goldenRatio);
    grid-template-areas: "title title"
      "image description"
      "image sell-area"
      "image extras";

    &.bundle-product {
      grid-template-columns: 40% auto;
      grid-template-areas: "image title"
        "image origin"
        "image description"
        "image sell-area"
        "image extras";

      .origin,
      .description {
        text-align: center;
      }

      .sell-area {
        text-align: center;

        .prices {
          grid-template-areas: "promo-price-area channel-price-area"
            "promo-label-area .";
        }

        .actions {
          margin-right: 50%;

          .product-do {
            display: inline-block;
            margin: auto;
          }
        }
      }
    }

    .title {
      align-self: end;

      .main {
        font-size: inherit;
        color: var(--color-primary);
      }

      .extra {
        font-size: smaller;
      }
    }

    .description {
      display: flex;
      flex-flow: column wrap;
    }

    .actions {
      display: flex;
      flex-flow: row wrap;
    }

    .sell-area {

      .winecard-price {
        display: none;
      }
    }

    .extras {
      // display: grid;
      // grid-template-columns: repeat(3, 1fr);
      // grid-template-rows: auto;

      // @include phone-and-tablet-vertical {
      //   grid-template-columns: repeat(1, 1fr);
      // }

      .item {

        display: grid;
        grid-template-columns: 1fr 2fr;
        grid-template-rows: auto;

        .item-label,
        .item-content {
          padding: $voidNarrow;
        }

        .item-label {
          font-size: $desktopFontSizeSmall;
          text-align: right;

          &::after {
            content: ":";
          }
        }

        .item-content {
          font-weight: bold;

          @include phone-and-tablet-vertical {
            text-align: left;
          }
        }
      }

      // .product-print {}
    }

    .product-settings {
      display: none;
    }

    dl,
    dt,
    dd,
    ul,
    li {
      margin: 0;
      padding: 0;
      display: block;
    }
  }


  a {
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &[data-status="con"] {
    .sell-add {
      display: none;
    }

    // .product-status-desc {
    //   cursor: not-allowed;
    // }
  }

}



@include tablet {
  .catalogue-row {
    font-size: $desktopFontSizeSmall;
  }

  .product {
    grid-template-areas: "image title"
      "image brand"
      "image origin"
      "image sell-area"
      "image description"
      "image extras"
      "image product-settings";
    grid-gap: $voidNarrow;

    .title {

      .main,
      .extra {
        display: block;
      }

      .main {
        font-size: $desktopFontSizeMedium;
      }
    }

    .sell-area {
      .prices {
        grid-template-rows: auto auto;
      }
    }
  }
}

@include tablet-horizontal {

  .product {
    grid-template-areas: "image title"
      "image brand"
      "image origin"
      "image sell-area"
      "image product-settings"
      "image description"
      "image extras";
  }
}

@include phone-and-tablet-vertical {

  .catalogue-row {
    padding: ($lineHeight * 1.5) 0;
  }

  .catalogue-row,
  .bundle-product-contents {
    .product {
      flex-basis: 100%;
      border-bottom: 1px solid $separatorColor;
      padding-bottom: $lineHeight;
      margin: $lineHeight $voidNarrow;
    }
  }
}


@include phone {

  .catalogue-row {
    font-size: $phoneFontSize;
  }

  .product {
    grid-template-columns: 1fr;
    grid-gap: $voidNarrow;
    grid-template-areas: "image"
      "title"
      "brand"
      "origin"
      "sell-area"
      "description"
      "extras"
      "product-settings";

    .image,
    .title,
    .brand,
    .origin,
    .extras,
    .sell-area {
      text-align: center;
    }

    // display image as inline-block to center it
    .image {
      &>* {
        display: inline-block;
      }
    }

    .title {

      .main,
      .extra {
        display: block;
      }

      .main {
        font-size: $phoneFontSizeBig;
      }

      .extra {
        font-size: $phoneFontSize;
      }
    }

    .brand {
      font-size: $phoneFontSizeBig;
    }

    .description {
      text-align: justify;
    }

    .extra {
      font-size: $phoneFontSizeBig;
    }

    .sell-area {
      .prices {
        grid-template-rows: auto auto;

        .channel-price,
        .channel-promo-price,
        .group-promo-price {
          .value {
            font-size: $phonePriceFontSize;
          }

          .extra {
            font-size: $phoneFontSize;
          }
        }
      }

      .actions {
        .product-do {
          display: inline-block;

          .add-to-basket,
          .add-to-favorites,
          .edit-winecard-btn {
            font-size: $phoneFontSize;
          }
        }
      }
    }

    &.product-detail {
      display: flex;
      flex-flow: row wrap;
      padding-left: $voidNarrow;
      padding-right: $voidNarrow;

      .description,
      .sell-area,
      .extras {
        margin-top: $voidDefault;
      }

      .sell-area {
        flex-basis: 100%;

        .prices {
          .group-label {

            .label {
              font-size: $phoneFontSizeSmall;
            }
          }
        }
      }

      .actions {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        .product-do {
          margin-left: initial;
          margin-bottom: $voidNarrow;
        }
      }

      .extras {
        width: 100%;

        .item {

          .item-label {
            font-size: $phoneFontSizeSmall;
          }
        }
      }
    }
  }
}


@include not-phone {
  .product {
    &.product-detail {
      grid-template-columns: 25% auto;
      grid-template-areas: "title title"
        "image description"
        "image sell-area"
        "image extras";

      .title {
        text-align: center;
      }
    }
  }
}

@import "catalogue-type-all.scss";
@import "catalogue-type-winecard.scss";
@import "catalogue-type-my-webshop.scss";