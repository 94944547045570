table {
    .valign-middle {
        vertical-align: middle !important;
    }
}



.row {
    &.featurette {
        margin-top: 3em;

        &.geschenken {
            text-align: center;
            margin-bottom: 4em;
        }
    }
}



.channel-promo,
.group-promo {
    &__text {
        font-size: 18px;
    }
}



.box-bottles {
    .catalogue-item {

        .channel-price,
        .group-promo-price {
            font-size: 24px;
        }

        .channel-price {
            text-decoration: line-through;
        }
    }

    .page-header {
        .label {
            &.label-default {
                display: none;
            }
        }
    }

    &.no-striped-prices {
        .catalogue-item {
            .channel-price {
                text-decoration: none;
            }
        }
    }
}


.badge-horizontal {
    /*

    <div class="badge-horizontal small free-shipping white-red">
        <div class="badge-text">
            Gratis bij uw thuis geleverd !
        </div>
    </div>

    <div class="badge-horizontal small white-gray-dark">
        <div class="badge-text">
            Gratis professionele kurkentrekker
        </div>
    </div>

    */

    --badge-margin-bottom: 0, 25em;

    --badge-width: 288px;
    --badge-height: 32px;
    --badge-padding-top: 6px;
    --badge-padding-bottom: 0px;
    --badge-padding-right: 14px;
    --badge-padding-left: 6px;
    --badge--free-shipping-padding-left: 56px;
    --badge-font-size: 14px;

    --badge--small-width: 216px;
    --badge--small-height: 24px;
    --badge--small-padding-top: 5px;
    --badge--small-padding-left: 10px;
    --badge--small-padding-bottom: 0px;
    --badge--small-padding-right: 10px;
    --badge--small--free-shipping-padding-left: 42px;
    --badge--small-font-size: 10px;

    display: inline-block;
    background-image: url($themeMediaPrefix + 'badge-horizontal.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    width: var(--badge-width);
    height: var(--badge-height);
    padding-top: var(--badge-padding-top);
    padding-bottom: var(--badge-padding-bottom);
    padding-left: var(--badge-padding-left);
    padding-right: var(--badge-padding-right);
    margin-bottom: var(--badge-margin-bottom);
    text-align: center;

    .badge-text {
        font-family: Bitter, Georgia, 'Times New Roman', Times, serif;
        font-weight: bold;
        font-size: var(--badge-font-size);
        color: var(--color-white);
    }

    &.gray-light {
        background-image: url($themeMediaPrefix + 'badge-horizontal--gray-light.svg');
    }

    &.gray-dark {
        background-image: url($themeMediaPrefix + 'badge-horizontal--gray-dark.svg');
    }

    &.white-red {
        background-image: url($themeMediaPrefix + 'badge-horizontal--white-red.svg');

        .badge-text {
            color: var(--color-red);
        }
    }

    &.white-gray-light {
        background-image: url($themeMediaPrefix + 'badge-horizontal--white-gray-light.svg');

        .badge-text {
            color: var(--color-gray-light);
        }
    }

    &.white-gray-dark {
        background-image: url($themeMediaPrefix + 'badge-horizontal--white-gray-dark.svg');

        .badge-text {
            color: var(--color-gray-dark);
        }
    }

    &.free-shipping {
        background-image: url($themeMediaPrefix + 'badge-horizontal--free-shipping.svg');
        padding-left: var(--badge--free-shipping-padding-left);
        text-align: left;

        &.gray-light {
            background-image: url($themeMediaPrefix + 'badge-horizontal--free-shipping-gray-light.svg');
        }

        &.gray-dark {
            background-image: url($themeMediaPrefix + 'badge-horizontal--free-shipping-gray-dark.svg');
        }

        &.white-red {
            background-image: url($themeMediaPrefix + 'badge-horizontal--free-shipping-white-red.svg');
        }

        &.white-gray-light {
            background-image: url($themeMediaPrefix + 'badge-horizontal--free-shipping-white-gray-light.svg');
        }

        .white-gray-dark {
            background-image: url($themeMediaPrefix + 'badge-horizontal--free-shipping-white-gray-dark.svg');
        }
    }

    &.small {
        width: var(--badge--small-width);
        height: var(--badge--small-height);
        padding-top: var(--badge--small-padding-top);
        padding-bottom: var(--badge--small-padding-bottom);
        padding-left: var(--badge--small-padding-left);
        padding-right: var(--badge--small-padding-right);
        margin-bottom: var(--badge--small-margin-bottom);

        &.free-shipping {
            padding-left: var(--badge--small--free-shipping-padding-left);
        }

        .badge-text {
            font-size: var(--badge--small-font-size);
        }
    }
}



.badge-round-wrapper {
    /*

    <div class="col-sm-4">
        <a href="#link_to_proefbox">
            <div class="badge-round-wrapper">
                <img src="https://cdn.hetwijnhuis.be/images/interface/smk-theme/placeholder.svg" data-original="https://cdn.hetwijnhuis.be/images/content/hw/page/header-product_id.jpg" class="img-responsive lazy"alt="Box">
                <div class="badge-round">
                    <div class="badge-round--text">
                        <span class="sell-price">
                            € XX,yy
                        </span>
                    </div>
                </div>
            </div>
        </a>
    </div>

    */

    position: relative;

    .badge-round {

        --font-size: 27px;
        --text-rotation: -18deg;

        --badge-round-width: 140px;
        --badge-round-height: 140px;
        --badge-round-bottom: 8%;
        --badge-round-right: 0;

        --badge-round--text-padding-top: 35%;
        --badge-round--text-padding-right: 10%;

        width: var(--badge-round-width);
        height: var(--badge-round-height);
        position: absolute;
        pointer-events: none;
        bottom: var(--badge-round-bottom);
        right: var(--badge-round-right);

        background-image: url($themeMediaPrefix + "badge-round.svg");
        background-repeat: no-repeat;
        background-size: 100%;

        &--text {
            padding-top: var(--badge-round--text-padding-top);
            padding-right: var(--badge-round--text-padding-right);
            text-align: center;
            transform: rotate(var(--text-rotation));

            .sell-price {
                color: var(--color-white);
                font-size: var(--font-size);
            }
        }
    }
}



.mega-header {
    /*

    <div class="mega-header mega-header--gray-lighter">
        <div class="container text-center">
            <h1>
                <span class="text-muted">muted_text</span> normal_text
            </h1>
        </div>
    </div>

    */

    font-size: 1.5em;
    // remove padding and margin to use .m-* and .p-* in LiveCanvas 
    // padding: 2.5em 0;
    // margin-top: 3em;

    h1 {
        font-size: 1.85em;
    }

    h2 {
        font-size: 1.45em;
    }

    h3 {
        font-size: 1.25em;
    }

    &>.container {
        &>h1 {
            margin-top: 0;
        }
    }

    a {
        text-decoration: underline;
        font-weight: bold;

        &.btn {
            text-decoration: none;
        }
    }

    &--red {
        background-color: var(--color-red);
        color: var(--color-gray-light);

        h1,
        h2,
        h3 {
            color: var(--color-white);
        }

        a {
            color: var(--color-white);
        }

        .text-muted {
            color: var(--color-gray-light);
        }
    }

    &--gray-dark {
        background-color: var(--color-gray-dark);
        color: var(--color-gray-light);

        h1,
        h2,
        h3 {
            color: var(--color-white);
        }

        a {
            color: var(--color-white);
        }

        .text-muted {
            color: var(--color-gray-light);
        }
    }

    &--gray-light {
        background-color: var(--color-gray-light);
        color: var(--color-gray-dark);
    }

    &--gray-lighter {
        background-color: var(--color-gray-lighter);
        color: var(--color-gray-dark);
    }

    &--gray-light,
    &--gray-lighter {

        h1,
        h2,
        h3 {
            color: var(--color-red);
        }
    }

    &.bg-image {
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;

        @media only screen and (max-width: 600px) {
            background-size: 500%;
        }

        &--geschenken {
            background-image: url($pageMediaPrefix + 'header-het-wijnhuis-cadeaubox.jpg');
        }

        &--solden {
            background-image: url($pageMediaPrefix + 'header-solden-2019-01.svg');
        }

        &--rioja {
            background-image: url($pageMediaPrefix + 'header-rioja-special-2019-02.jpg');
        }

        &--openfles-201903 {
            background-image: url($pageMediaPrefix + 'openfles/2019/03/teaser2-lente-openfles-dagen-2019-03.jpg');
        }
    }

    .text-shadow {
        &--white {
            text-shadow: 0 0 0.25em #fff;
        }

        &--black {
            text-shadow: 0 0 0.25em #000;
        }
    }
}


//
//
// .blurry-block

// <div class="col">
//     <a href="javascript:void(0);" onpointerup="alert('Click!');">
//         <div class="blurry-block">
//             <div class="bb-background">
//                 <img class="img-fluid" src="https://via.placeholder.com/1024x768" alt="demo img">
//             </div>
//             <div class="bb-foreground bb-dark">
//                 <div class="bb-content w-100 h-100 p-3 d-flex justify-content-center align-items-center">
//                     <div class="text-center align-self-center">
//                         <h4 class="my-0 text-white">
//                             Title
//                         </h4>
//                         <div class="mt-2 text-white">
//                             Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptas est consectetur architecto quaerat eveniet enim reiciendis sed ab aut laudantium corporis quos amet asperiores rerum dolore, illum similique? Saepe, laborum?
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </a>
// </div>

@mixin blurry-on-hover {
    backdrop-filter: blur(10px);

    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }
}

.blurry-block {

    .bb {

        @include using-mouse {

            &-background,
            &-foreground {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            &-foreground {
                transition-duration: 0.25s;

                .bb-content {
                    display: none !important;
                }

                &:hover {
                    @include blurry-on-hover;

                    &.bb-light {
                        background: rgba(255, 255, 255, 0.2);
                    }

                    &.bb-dark {
                        background: rgba(0, 0, 0, 0.5);
                    }

                    .bb-content {
                        display: flex !important;
                    }
                }

            }
        }

        @include using-touch {
            &-foreground {
                display: none !important;
            }
        }
    }
}

.call-to-action {
    &--stretched {
        background-color: var(--color-gray-lighter) !important;
        color: var(--color-primary) !important;

        &:hover,
        &:active {
            text-decoration: none;
        }

        @include using-mouse {
            &:hover {
                color: var(--color-white) !important;
                background-color: var(--color-primary) !important;
            }
        }

        @include using-touch {

            &:hover,
            &:active {
                color: var(--color-white) !important;
                background-color: var(--color-primary) !important;
            }
        }
    }
}