.product-search {

    @keyframes submit-pulse {
        0% {
            // background-color: var(--color-white);
            // color: var(--color-primary);
            box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.75);
        }

        100% {
            // background-color: var(--color-primary);
            // color: var(--color-white);
            box-shadow: 0 0 0 18px rgba(255, 255, 255, 0);
        }
    }

    .search-submit {
        transition: .5s;

        &.enabled {
            animation: submit-pulse 2s infinite;
        }
    }

    .filter-display__wrapper {
        .filter-display {

            &.remove-item {
                &:hover {
                    .subgroup-desc {
                        text-decoration: line-through;
                    }
                }
            }

            .group-desc {
                &::after {
                    content: ": ";
                }
            }

            .subgroup-desc {
                color: var(--color-primary);
            }

            .icon {
                display: inline-block;
                width: 1rem;

                &::after {
                    content: "✖️";
                }
            }
        }
    }

    .catalogue-search__menu {

        .filters-list {
            ul {
                li {
                    display: block;

                    >* {
                        display: block;
                        padding: var(--header-empty-space-vertical) var(--header-empty-space-horizontal);
                    }
                }
            }

            .filters-header {
                padding: var(--header-empty-space-vertical) var(--header-empty-space-horizontal);
                margin-top: var(--header-empty-space-vertical);

                &.h4 {
                    border-bottom: 1px solid var(--color-gray-dark);
                }
            }

            .filter-select {
                &.selectable {
                    cursor: pointer;
                    color: var(--color-gray-dark);

                    .subgroup-desc {
                        &:hover {
                            background-color: var(--color-primary);
                            color: var(--color-gray-lighter);
                        }
                    }
                }

                &.selected {
                    .subgroup-desc {
                        color: var(--color-gray);
                        font-weight: bold;
                        text-decoration: line-through;

                        &::after {
                            content: " ✓";
                        }
                    }
                }
            }
        }
    }

}



//
//
//
// TABLET
//
//

// @include tablet {
//     .product-search {}
// }



//
//
//
// PHONE
//
//

@include phone {
    .product-search {

        .filters-display__panel {

            .filter-display__wrapper {
                .filter-display {
                    font-size: var(--font-size-small);
                }
            }
        }
    }
}