#divProductCatalogue {

    &[data-catalogue-type="catalogue"],
    &[data-catalogue-type="winecard"],
    &[data-catalogue-type="my-webshop"] {
        .product {

            // hide winecard price by default
            .winecard-price {
                display: none;
            }
        }
    }

    &[data-catalogue-type="winecard"],
    &[data-catalogue-type="my-webshop"] {

        .catalogue-row {
            padding: initial;

            .products-group--title {
                flex: 0 0 100%;
                margin-top: 1.62rem;
                margin-bottom: 1rem;
                padding-bottom: $voidNarrow;
                border-bottom: 1px solid $headings-color;

                color: $headings-color;
                // text-align: center;

                font-family: $headings-font-family;
                font-weight: $headings-font-weight;
                line-height: $headings-line-height;
                @include font-size($h1-font-size);

                // @include font-size($display4-size);
                // font-weight: $display4-weight;
                // line-height: $display-line-height;
            }

            .product {

                @include phone {

                    .title,
                    .origin,
                    .brand,
                    .extras,
                    .sell-area {
                        text-align: left;
                    }
                }
            }
        }
    }
}