input,
textarea,
select {
    // Use system font for input fields
    font-family: $font-family-system;
    font-size: $font-size-base !important;

    // Prevent iOS zoom when tapping inside input
    // by setting minimum font size to 16px
    @include phone-vertical {
        font-size: clamp(16px, var(--font-size-base), var(--font-size-big)) !important;
    }

    @include phone-horizontal {
        font-size: clamp(16px, var(--font-size-base), var(--font-size-big)) !important;
    }
}

.custom-switch {
    .custom-control-input:checked~.custom-control-label {
        &::before {
            // force color green for custom switches when checked
            border-color: var(--green);
            background-color: var(--green);
        }
    }
}

@mixin text-shadow-black {
    text-shadow: #000 1px 0 8px;
}

@mixin text-shadow-white {
    text-shadow: #fff 1px 0 8px;
}

@mixin white-text-with-shadow {
    color: #fff;
    @include text-shadow-black;
}

@mixin black-text-with-shadow {
    color: #000;
    @include text-shadow-white;
}

.text {
    &-shadow {
        &-black {
            @include text-shadow-black;
        }

        &-white {
            @include text-shadow-white;
        }
    }

    &-white {
        @include white-text-with-shadow;
    }

    &-black {
        @include black-text-with-shadow;
    }
}

.blurry-bg {

    &-light,
    &-dark {
        @include blurry-bg;
    }

    &-light {
        background: rgba(255, 255, 255, 0.65);
    }

    &-dark {
        background: rgba(0, 0, 0, 0.65);
    }
}

.separator-char {
    display: inline-block;
    padding-left: $voidTiny;
    padding-right: $voidTiny;

    &::after {
        content: $separatorChar;
    }
}