// bootbox.js overrides

.bootbox {
    &.modal {
        .modal {
            &-content {
                @include shadow-lg;
                border: $voidNarrow solid rgba(0, 0, 0, 0.35);
            }
        }
    }
}