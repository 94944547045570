@mixin group-promo-label {
    @if $catalogueStyle=='modern-one' {
        color: var(--color-group-promo);
        font-weight: $font-weight-bold;
        text-align: center;

        // background-color: var(--color-white);
        // border: 1px solid var(--color-group-promo);

        // border: 1px solid var(--light);
        // border-radius: $border-radius !important;
        // @include shadow-sm;
    }

    @else {
        @include promo-price-bg-color;
        @include promo-price-color-complementary;
        border-radius: $lineHeight / 1.25;
    }
}

.label,
.promo-description {
    line-height: initial;
    padding: ($voidNarrow / $goldenRatio) $voidDefault;
    display: inline-block;
    white-space: initial;

    &.label-group-promo {
        @include group-promo-label;
    }
}

.label {
    font-size: $labelFontSize;
}

.label+.promo-description {
    margin-left: 1rem;
}

.promo-description {
    padding-left: $voidNarrow;
    padding-right: $voidNarrow;
    font-size: $promoFontSize;

    @if $conditionsBetweenBrakets==true {
        &::before {
            content: '( ';
        }

        &::after {
            content: ' )';
        }
    }

    &.group,
    &.channel {
        &-promo {

            .qty,
            .price {
                white-space: nowrap;
                font-weight: bold;
                color: $promoColor;
            }
        }
    }
}