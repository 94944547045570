// Bootstrap 3 .btn-default becomes .btn-secondary in BS4
.btn {

    &-default {
        @include button-variant($secondary, $secondary);
    }
}

// Bootstrap 3 .img-responsive becomes .img-fluid in BS4
.img {
    &-responsive {
        @include img-fluid();
    }
}