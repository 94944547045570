@mixin warning-appearance {
    margin: 48px !important;
    padding: 48px !important;
    background-color: lightyellow !important;
    color: darkred;
    border: 1px solid darkred;
    font-weight: bold;
    font-size: large;
}

@mixin incompatible-browser-msg {
    content: "¯\_(⊙_ʖ⊙)_/¯ The browser you are using is obsolete and it’s not fully supported on this website. Please use a more modern browser, thank you. //// De browser die u gebruikt, is verouderd en wordt niet volledig ondersteund op deze website. Gelieve een modernere browser geruiken, bedankt. //// Le navigateur que vous utilisez est obsolète et n’est pas entièrement compatible avec ce site Web. Veuillez utiliser un navigateur plus moderne, merci. //// Il browser che stai utilizzando è obsoleto e non è supportato su questo sito web. Ti invitiamo ad utilizzare un browser più moderno, grazie. ( ͡• ͜ʖ ͡• )";
}

@mixin javascript-disabled-msg {
    content: "¯\_(⊙_ʖ⊙)_/¯ Something went wrong while loading this page. Javascript is necessary for this website to work correctly. //// Er is iets misgegaan bij het laden van deze pagina. Javascript is nodig om deze website correct te laten werken. //// Une erreur s’est produite lors du chargement de cette page. Javascript est nécessaire pour que ce site fonctionne correctement. //// Qualcosa è andato storto durante il caricamento di questa pagina. Javascript è necessario affinché questo sito web funzioni correttamente. ( ◔ ʖ̯ ◔ )";
}

html {

    &.no-js {

        body {

            >* {
                @include warning-appearance;

                &::before {
                    @include javascript-disabled-msg;
                }
            }

            .btn,
            button,
            input,
            a {
                display: none !important;
            }
        }

    }

    &.js {

        // Internet Explorer 11 (Trident)
        // &.no-flexboxlegacy.no-websqldatabase.no-cssreflections.no-smil
        &.no-flexboxlegacy {

            body {

                >* {
                    @include warning-appearance;

                    &::before {
                        @include incompatible-browser-msg;
                    }
                }

                .btn,
                button,
                input,
                a {
                    display: none !important;
                }
            }
        }
    }
}