header {
    &.SMK-Theme {

        &.has-search {
            &__active {
                // border: 3px solid green;

                .webshop {
                    &-megasearch {
                        flex-grow: 1;
                    }
                }
            }

            &__inactive {
                // border: 3px solid blue;
            }
        }

        .webshop {

            &-megasearch {
                // border: 1px solid red;

                &.navbar-container {
                    .navbar-menu {

                        .submenu {
                            // border: 1px solid yellow;
                            width: 100%;

                            .submenu-btn {
                                // border: 1px solid red;
                                padding: 0;
                                display: flex;
                                flex-direction: column;
                                flex-wrap: nowrap;

                                // background-color: blue;

                                &.search-nav {
                                    max-width: var(--navbar-megasearch-max-width);
                                }
                            }

                            .megasearch {
                                width: 100%;

                                &-button {
                                    padding: {
                                        top: $voidDefault;
                                        bottom: $voidDefault;
                                        left: $voidDefault*2;
                                        right: $voidDefault;
                                    }
                                }

                                &-form-wrapper {
                                    display: flex;
                                    width: 100%;
                                    margin: auto;
                                }

                                &-filters-wrapper {
                                    display: none;
                                }

                                &-filters {
                                    display: none;
                                }

                                &-form {
                                    display: none;
                                }
                            }

                            .badge {
                                &.active-searches {
                                    display: none;
                                }
                            }

                            .previous-search-breadcrumbs {
                                display: inline-block;
                                vertical-align: top;
                                pointer-events: none;

                                .filter-display,
                                .previous-keywords {
                                    pointer-events: none;
                                    background-color: transparent;
                                    color: var(--navbar--color);
                                    border: none;

                                    &::before {
                                        content: "✓ ";
                                    }
                                }

                                .filter-display__wrapper {

                                    .group-desc,
                                    .icon {
                                        display: none;
                                    }
                                }
                            }

                            &.open {

                                .submenu-btn {
                                    background-color: var(--navbar--active--bg-color);
                                }

                                .previous-search-breadcrumbs {
                                    display: none;
                                }

                                .megasearch {
                                    &-filters-wrapper {
                                        display: block;
                                        position: relative;
                                        width: 100%;
                                    }

                                    &-filters {
                                        position: absolute;
                                        display: block;
                                        left: 0;
                                        right: 0;
                                        flex-grow: 1;
                                    }

                                    &-form {
                                        display: block;
                                        flex-grow: 1;

                                        .ms-form {
                                            // border: 1px solid lightgreen;
                                            display: grid;
                                            grid-template-columns: 80% 20%;
                                            grid-template-rows: 50% 50%;
                                            grid-template-areas: "keywords submit"
                                                "filters submit";
                                            justify-items: stretch;
                                            align-items: stretch;

                                            .ms {
                                                &-keywords {
                                                    grid-area: keywords;
                                                }

                                                &-filters {
                                                    grid-area: filters;
                                                }

                                                &-submit {
                                                    grid-area: submit;
                                                }

                                                &-keywords,
                                                &-filters,
                                                &-submit {
                                                    // border: 1px solid yellow;
                                                    display: flex;
                                                    justify-content: space-between;
                                                    align-items: stretch;
                                                    align-content: stretch;

                                                    >* {
                                                        // border: 1px solid lightgreen;
                                                        flex-grow: 1;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }
}



//
//
//
// PHONE
//

@include phone {
    header {
        &.SMK-Theme {

            &.has-search {
                &__active {

                    .webshop {
                        &-sell {
                            display: none;
                        }
                    }
                }

                // &__inactive {}
            }

            .webshop {

                align-items: center;

                &-megasearch {
                    &.navbar-container {
                        .navbar-menu {
                            .submenu {

                                .badge {
                                    &.active-searches {
                                        display: initial;

                                        &:empty {
                                            display: none;
                                        }
                                    }
                                }

                                .previous-search-breadcrumbs {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                #popupBasketLink {
                    .name {
                        display: none;
                    }
                }
            }
        }
    }
}