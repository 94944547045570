.master-common {
    // .common {
    //     &--main,

    //     &--baskets,
    //     &--billing,
    //     &--delivery,
    //     &--favorite,
    //     &--giftcertificates,
    //     &--history,
    //     &--menu,
    //     &--orders,
    //     &--sales,
    //     &--suggestions,
    //     &--userdata {}
    // }

    .common {

        &--billing,
        &--delivery,
        &--sales {
            padding-top: $font-size-base * 4;
        }

        &--favorite {

            .catalogue-row {
                // padding: initial;

                .product {
                    flex: 0 0 100%; // display flex children as rows
                    grid-template-columns: 20% 40% auto;
                    grid-template-areas:
                        "image title title"
                        "image origin origin"
                        "image brand brand"
                        "image description description"
                        "image extras extras"
                        "image sell-area ."
                        "image product-settings ."
                        "image . .";

                    @include phone {

                        .title,
                        .brand,
                        .origin,
                        .description,
                        .extras,
                        .sell-area {
                            text-align: left;
                        }
                    }

                    @include phone-vertical {
                        grid-template-columns: 30% auto;
                        grid-template-areas:
                            "image title"
                            "image origin"
                            "image brand"
                            "image description"
                            "image extras"
                            "sell-area sell-area"
                            "product-settings product-settings";

                        .sell-area {
                            text-align: center;
                        }
                    }

                    // .title {}

                    // .brand { }

                    // .origin {}

                    // .image {}

                    // .description {}

                    // .extras {}

                    .sell-area {
                        .prices {
                            grid-template-rows: auto;
                        }
                    }

                    // .product-settings {}
                }
            }
        }

        &--history {
            .catalogue-row {
                .product {

                    &[data-status="dis"],
                    &[data-status="out"] {
                        .sell-add {
                            display: none !important;
                        }

                        .product-settings {
                            display: none !important;
                        }
                    }
                }
            }
        }
    }

    .common-header {
        margin-top: $font-size-base * 4;
        text-align: center;
    }
}