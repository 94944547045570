header {

    &.SMK-Theme {
        border-bottom: 1px solid var(--header-border-color);
        font-size: var(--header-font-size);
        background-color: var(--header-main-bgcolor);
        color: var(--header-main-color);

        display: grid;
        grid-template-columns: var(--logo-desktop-width) auto auto;
        // this grid-template-rows can make logo not to fit on its bottom
        // grid-template-rows: 25% 45% 30%;
        grid-template-rows: auto 45% auto;
        grid-template-areas: "news news news"
            "channel webshop webshop"
            "channel navigation navigation";
        align-items: stretch;
        justify-items: stretch;

        .channel {
            grid-area: channel;

            .name {
                display: none;
            }

            .logo {

                &.web {
                    display: block;
                }

                &.icon {
                    display: none;
                }
            }
        }

        .news {
            grid-area: news;

            display: flex;
            align-items: center;
            justify-content: flex-start;

            // .message {
            //     margin-left: var(--navbar--void-horizontal);
            //     padding-left: var(--word-spacing);
            // }
        }

        .navigation {
            grid-area: navigation;

            display: flex;
            align-items: center;
            justify-content: space-between;

            .navbar-container {
                .navbar-menu {
                    .submenu {

                        &.open {
                            .submenu-btn {
                                background-color: var(--navbar--active--bg-color);
                                color: var(--navbar--active--color);

                                &:hover {
                                    background-color: var(--navbar--active--bg-color__hover);
                                    color: var(--navbar--active--color__hover);
                                }
                            }
                        }
                    }
                }
            }
        }

        .webshop {
            grid-area: webshop;

            display: flex;
            justify-content: space-between;

            .navbar-container {
                .navbar-menu {
                    background-color: initial;

                    .submenu {

                        .submenu-content {
                            color: var(--header-main-color);
                        }
                    }
                }
            }

            // &-search {}

            // &-sell {}
        }

        .navbar-container {

            height: 100%;

            ul,
            li {
                list-style: none;
                margin: 0;
                padding: 0;
            }

            a {
                text-decoration: none;
            }

            .serv-menu {
                .icon {
                    +.name {
                        padding-left: var(--word-spacing);
                    }
                }
            }

            .navbar-menu {
                background-color: var(--navbar-bg-color);

                font-size: var(--navbar--font-size);

                height: 100%;
                display: flex;
                align-items: stretch;

                .nav-item,
                .submenu-btn,
                .submenu-content {
                    transition: 0.25s;
                }

                .nav-item {
                    color: var(--navbar-link-color);
                    padding: var(--navbar--void-vertical) var(--navbar--void-horizontal);
                    display: flex;
                    align-items: center;

                    @include not-phone {
                        &.login-link {
                            padding: initial;
                        }
                    }
                }

                .submenu {

                    .submenu-btn,
                    .login-link {
                        height: 100%;
                        border: none;
                        outline: none;

                        padding: {
                            // top: var(--navbar--void-vertical);
                            // bottom: var(--navbar--void-vertical);
                            left: var(--navbar--void-horizontal);
                            right: var(--navbar--void-horizontal);
                        }

                        margin: 0;
                        display: flex;
                        align-items: center;

                        .name,
                        .icon,
                        .badge {
                            line-height: var(--header-line-height);
                        }

                        .icon+.name,
                        .name+.icon {
                            padding-left: var(--word-spacing);
                        }

                        .name+.badge {
                            margin-left: var(--word-spacing);
                            padding: 0 var(--word-spacing);
                        }

                        .icon {

                            &.if-closed {
                                display: inline-block;
                            }

                            &.if-open {
                                display: none;
                            }
                        }

                        .badge {

                            &.active-searches {
                                background-color: var(--navbar--active--bg-color);
                                color: var(--navbar--active--color__hover);
                            }
                        }

                        &.input-container {
                            padding: 0;
                        }
                    }

                    &-content {
                        display: none;
                        position: absolute;
                        background-color: var(--color-gray-lighter);
                        z-index: 1;

                        border-bottom: var(--navbar-border-size) var(--navbar-border-style) var(--navbar--active--bg-color);
                        box-shadow: var(--shadow-h-offset) var(--shadow-v-offset) var(--shadow-blur) var(--shadow-spread) var(--shadow-color);

                        &.full-width {
                            left: 0;
                            width: 100%;
                        }

                        .nav-item {
                            float: left;
                        }

                        a {

                            &.nav-item {
                                color: var(--navbar-link-color);
                                text-decoration: none;

                                &:hover {
                                    color: var(--navbar-link-color__hover);
                                    background-color: var(--navbar-link-bg-color__hover);
                                }
                            }
                        }

                        .user-name,
                        li.item a {
                            display: block;
                            width: 100%;
                            padding: var(--navbar--void-vertical) var(--navbar--void-horizontal);

                            font-weight: bold;
                            text-decoration: none;
                        }

                        .submenu-header {
                            display: flex;
                            justify-content: flex-end;
                            min-height: 32px;
                            padding: var(--navbar--void-small-vertical) var(--navbar--void-horizontal);

                            background-color: var(--navbar--active--bg-color);
                            color: var(--navbar--active--color);

                            .title,
                            .actions {
                                display: flex;
                                align-items: center;
                            }

                            .title {
                                .text {
                                    font-size: var(--font-size-small);
                                }
                            }

                            .actions {
                                .close {
                                    // Reset boostrap .close styles
                                    color: inherit;
                                    text-shadow: none;
                                    opacity: 1;

                                    .close-icon {
                                        display: inline-block;
                                        padding: var(--navbar--void-small-vertical) var(--navbar--void-small-horizontal);
                                    }
                                }
                            }
                        }

                        .submenu-body {
                            @include not-phone {
                                max-height: $desktopModalContentHeight;
                            }

                            overflow: auto;
                        }
                    }

                    &.open {
                        .submenu-content {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                        }

                        .nav-item,
                        .submenu-btn {
                            color: var(--navbar-link-color);
                            background-color: inherit;
                        }

                        .submenu-btn {
                            .icon {
                                &.if-closed {
                                    display: none;
                                }

                                &.if-open {
                                    display: inline-block;
                                }
                            }

                            .name {
                                text-decoration: underline;
                            }

                            .badge {

                                &.active-searches {
                                    background-color: var(--navbar--active--color__hover);
                                    color: var(--navbar--active--bg-color__hover);
                                }
                            }
                        }
                    }

                    &.basket-link-wrapper {
                        .submenu-btn {
                            color: var(--navbar--color);

                            &:hover {
                                background-color: var(--navbar--dark--link-bg-color__hover);
                                color: var(--navbar--dark--link-color__hover);

                                .badge {
                                    color: var(--navbar--dark--link-bg-color__hover);
                                    background-color: var(--navbar--dark--link-color__hover);
                                }
                            }
                        }
                    }
                }

                a.nav-item,
                .submenu .submenu-btn {
                    background-color: inherit;
                    color: inherit;
                    font-family: inherit;
                    font-weight: var(--navbar--link-font-weight);
                }

                a.nav-item:hover,
                .submenu.open .submenu-btn {
                    color: var(--navbar-link-color__hover);
                    background-color: var(--navbar-link-bg-color__hover);
                }

                .contact-login-menu {

                    .contact-login {
                        &.user-name {
                            display: flex;
                            align-items: center;
                            margin: auto 0;
                            padding: var(--navbar--void-vertical) var(--navbar--void-horizontal);
                            height: 100%;
                        }

                        &:hover {
                            color: var(--navbar-link-color__hover);
                            background-color: var(--navbar-link-bg-color__hover);
                        }
                    }

                    &.dropdown {

                        .dropdown-menu {

                            li {

                                &.dropdown-header,
                                &.service-button {
                                    padding: var(--navbar--void-vertical) var(--navbar--void-horizontal) 0 var(--navbar--void-horizontal);
                                    // color: var(--navbar-dark-user-accent-color);
                                }

                                &.item {

                                    a {
                                        width: 100%;
                                        padding: var(--navbar--void-vertical) var(--navbar--void-horizontal);
                                        font-weight: bold;
                                        text-decoration: none;
                                    }
                                }

                                &.close {
                                    display: none;
                                }
                            }
                        }
                    }

                    .logged-user-menu {
                        &.user-links-menu {
                            padding: var(--navbar--void-vertical) 0;

                            .item {
                                .user-link {
                                    display: block;

                                    @include not-phone {
                                        color: var(--navbar--dark--color);
                                    }
                                }
                            }

                            .divider {
                                display: block;
                                float: none;
                                border-bottom: var(--navbar-border-size) solid var(--navbar-border-color);
                                margin: var(--navbar--void-vertical) 0;

                            }
                        }
                    }
                }
            }
        }

        button {
            cursor: pointer;
        }

        &.sticky-top {
            // Sticky header:
            position: fixed;
            width: 100%;
            z-index: $zindex-navbar-fixed;
            background-color: var(--header-main-bgcolor);
        }

    }
}



@include not-phone {

    .SMK-Theme {

        &.sticky-top {

            .navbar-container {
                .navbar-menu {

                    .submenu {
                        // &.open {

                        //     .submenu-content {
                        //         max-height: calc(var(--remaining-window-height) - var(--shadow-height-calculated));
                        //     }
                        // }

                        &.search {

                            .submenu-body {
                                padding-top: var(--navbar--void-vertical);
                                padding-bottom: var(--navbar--void-vertical);
                            }
                        }
                    }
                }
            }
        }
    }
}




//
//
//
// DESKTOP + TABLET HORIZONTAL
//
//

@include desktop-and-tablet {
    header {
        &.SMK-Theme {

            .news {
                background-color: var(--color-gray-darker) !important;
                color: var(--color-gray-lighter);
            }

            .webshop {
                background-color: var(--navbar--dark--bg-color);
                color: var(--navbar--dark--color);

                .navbar-container {
                    .navbar-menu {
                        .submenu {

                            .submenu-btn {
                                &:hover {
                                    color: var(--navbar-dark-user-accent-color);
                                }
                            }

                            a {
                                background-color: var(--navbar--dark--bg-color);
                                color: var(--navbar--dark--link-color);

                                &:hover {
                                    background-color: var(--navbar--dark--link-bg-color__hover);
                                    color: var(--navbar--dark--link-color__hover);
                                }
                            }

                            &.usermenu-wrapper {

                                &.logged-in {
                                    .user-name {
                                        font-weight: var(--navbar--link-font-weight);
                                        color: var(--navbar-dark-user-accent-color);
                                    }

                                    .submenu-btn {
                                        color: var(--navbar-dark-user-accent-color);
                                    }

                                }
                            }

                            &.basket-link-wrapper {
                                background-color: var(--navbar--dark--bg-color);
                                color: var(--navbar--dark--color);
                            }
                        }

                        .contact-login-menu {
                            .contact-login {
                                &.user-name {
                                    display: flex;
                                    align-items: center;
                                    margin: auto 0;
                                    padding: var(--navbar--void-vertical) var(--navbar--void-horizontal);
                                    height: 100%;
                                    background-color: var(--navbar--dark--bg-color);
                                    color: var(--navbar--dark--color);
                                }

                                &:hover {
                                    color: var(--navbar-link-color__hover);
                                    background-color: var(--navbar-link-bg-color__hover);
                                }
                            }

                            &.dropdown {

                                .dropdown-menu {
                                    background-color: var(--navbar--dark--bg-color);
                                    color: var(--navbar--dark--color);

                                    li {
                                        &.item {

                                            a {

                                                &:hover {
                                                    background-color: var(--navbar--dark--link-bg-color__hover);
                                                    color: var(--navbar--dark--link-color__hover);
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



//
//
//
// TABLET
//
//

// @include tablet {}



//
//
//
// PHONE + TABLET VERTICAL
//
//

@include phone-and-tablet-vertical {
    header {

        &.SMK-Theme {
            grid-template-columns: var(--logo-tablet-width) auto;
            grid-template-rows: auto auto auto;
            grid-template-areas: "channel navigation"
                "webshop webshop";

            .channel {

                .logo {

                    &.web {
                        display: none;
                    }

                    &.icon {
                        display: block;
                    }
                }
            }

            .news {
                display: none;
            }

            .navigation {

                .navbar-container {
                    .navbar-menu {
                        flex-wrap: wrap;

                        .submenu {
                            .submenu-btn {
                                flex-grow: 1;
                            }
                        }
                    }
                }
            }

            .webshop {
                // background-color: var(--navbar--bg-color);
                // color: var(--navbar--color);

                // display: flex;
                // flex-direction: column;
                // align-items: center;

                &-search {
                    overflow: hidden;
                    order: 1;
                }

                &-sell {}

                .navbar-container {
                    .navbar-menu {
                        .submenu {
                            &.basket-link-wrapper {
                                background-color: var(--navbar--bg-color);
                                color: var(--navbar--link--color);
                            }

                            .contact-login {

                                &.login-link {
                                    // background-color: var(--navbar--dark--bg-color);
                                    color: var(--navbar--dark--color);
                                }
                            }
                        }

                        .contact-login-menu {
                            .contact-login {

                                &.user-name {
                                    // background-color: var(--navbar--dark--bg-color);
                                    color: var(--navbar--dark--color);
                                    padding-top: initial;
                                    padding-bottom: initial;
                                }

                                &.login-link {
                                    color: white !important;
                                }

                                // &:hover {
                                //     color: var(--navbar--dark--link-color__hover);
                                //     background-color: var(--navbar-link-bg-color__hover);
                                // }
                            }
                        }
                    }
                }
            }
        }
    }
}



//
//
//
//  PHONE
//
//

@include phone {
    header {

        &.SMK-Theme {
            // grid-template-columns: var(--logo-phone-width) auto;
            // grid-template-areas: "webshop webshop"
            //     "channel navigation";

            font-size: var(--font-size-small);

            .navbar-container {
                .navbar-menu {
                    .submenu {

                        &-content {
                            border-bottom: none;
                            box-shadow: initial;
                            padding-bottom: var(--navbar--void-vertical);

                            .nav-item {
                                float: none;
                                display: block;
                                width: 100%;
                            }
                        }

                        &.open {
                            .submenu-content {
                                display: flex;
                                flex-direction: column;
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100vw;
                                height: 100vh;
                                overflow-y: auto;
                            }
                        }
                    }

                    .contact-login-menu {
                        padding: initial;

                        &.dropdown {
                            position: initial;

                            .close {
                                display: none !important;
                            }

                            &.show {
                                .logged-user-menu {
                                    &.dropdown-menu {
                                        position: absolute;
                                        transform: initial !important;
                                        top: 0;
                                        left: 0;
                                        width: 100vw;
                                        height: 100vh;
                                        margin: 0;
                                        border-radius: 0;
                                        border: none;

                                        display: flex;
                                        flex-direction: column;
                                        align-content: flex-start;
                                        justify-content: flex-start;
                                        overflow-y: scroll;
                                    }
                                }

                                .service-button {
                                    display: block !important;

                                    // .action {
                                    //     &__close {}

                                    //     .icon {}

                                    //     .text {}
                                    // }
                                }
                            }
                        }
                    }
                }
            }

            .news {
                display: none;
            }

            .webshop {
                background-color: var(--navbar--dark--bg-color);
                color: var(--navbar--dark--color);

                .navbar-container {
                    .navbar-menu {
                        .submenu {

                            .submenu-content {
                                left: 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .navigation {

                .navbar-container {
                    .navbar-menu {
                        .submenu {
                            .submenu-btn {
                                padding-left: $voidNarrow;
                                padding-right: $voidNarrow;

                                // .icon {
                                //     &.if-closed {
                                //         display: none;
                                //     }
                                // }

                                .name {

                                    &+.icon {
                                        display: none;
                                    }
                                }
                            }

                            &.search {

                                .icon {
                                    &.if-closed {
                                        display: initial;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

//
//
//
// PHONE VERTICAL
//
//

@include phone-vertical {
    header {

        &.SMK-Theme {
            grid-template-columns: var(--logo-phone-width) auto;
        }
    }
}